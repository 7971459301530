import { createContext, useContext, useEffect, useState } from "react"
const UserContext = createContext()

export const UserProvider = ({ children }) => {
    const [currentproject, setcurrentproject] = useState(null)
    const [isLoading, setisLoading] = useState(true)
    const [currentUser, setcurrentUser] = useState(null)
    useEffect(() => {
        localStorage.setItem('myState', JSON.stringify(currentUser));
    }, [currentUser]);
    return (
        <UserContext.Provider value={{
            currentproject, setcurrentproject,
            isLoading, setisLoading,
            currentUser, setcurrentUser
        }}>
            {children}
        </UserContext.Provider>
    )

}
export const useAuth = () => useContext(UserContext)