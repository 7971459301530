import React, { useState, useEffect } from 'react';
import { Box, Button, Container, TextField, Grid, MenuItem, FormControl, useTheme, FormLabel } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link, useNavigate } from 'react-router-dom';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';

const Addproject = ({ AddProjectToggle, setAddProjectToggle }) => {
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/project';
    const navigate = useNavigate()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const userid = localStorage.getItem("user")
    const uid = function () {
        return Date.now() + Math.floor(Math.random());
    }
    useEffect(() => {
        document.title = "iDeal - AddProject";
    }, [])
    const [project, setproject] = useState({
        userid: userid,
        id: uid(),
        projectname: "",
        projectowner: "",
        tags: "",
        projectdescription: "",
        startdate: "",
        finishdate: "",
        dealsigndate: "",
        dealclosedate: "",
        dealname: "",
        personnel: "",
        dealtype: "",
        market: "",
        dealvalue: "",
        personneldealdescription: "",
        progress: "",
        status: "",
    })
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setproject({
            ...project,
            [name]: value,
        });
    };


    useEffect(() => {
        setproject((prevproject) => ({
            ...prevproject,
            id: uid(),
        }));
    }, [setproject])
    const handelCreateproject = async (e) => {
        e.preventDefault();
        // console.log(project)
        try {
            const response = await fetch(lambdaEndpoint, {
                method: 'POST',
                headers: {
                    "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
                },
                body: JSON.stringify(project),
            });
            const data = await response.json();
            // console.log('Response:', response);
            // console.log('Response Data:', data);
        } catch (error) {
            console.error('Error:', error);
        }
        setproject({
            userid: userid,
            id: uid(),
            projectname: "",
            projectowner: "",
            tags: "",
            projectdescription: "",
            startdate: "",
            finishdate: "",
            dealsigndate: "",
            dealclosedate: "",
            dealname: "",
            personnel: "",
            dealtype: "",
            market: "",
            dealvalue: "",
            personneldealdescription: "",
            progress: "",
            status: "",
        })
        setAddProjectToggle(!AddProjectToggle)
    }
    return (
        <div
            data-te-modal-init
            className={` fixed left-0 top-0 z-[1055] ${AddProjectToggle ? "block" : "hidden"} h-full w-full overflow-y-auto overflow-x-hidden outline-none`}
            id="exampleModalLg"
            tabIndex="-1"
            aria-labelledby="exampleModalLgLabel"
            aria-modal="true"
            role="dialog">
            <div
                className={`${theme.palette.mode === 'light' ? 'bg-[#eef0f2]' : 'bg-[#141b2d]'} 2xl:mt-[5px] 2xl:ml-[235px] 
                xl:mt-[5px] xl:ml-[150px] lg:mt-[5px] lg:ml-[100px] md:mt-[70px] md:ml-[70px] max-sm:mt-[50px] max-sm:ml-[50px] pointer-events-none  relative w-[80%] duration-300 ease-in-out`}
                style={{
                    boxShadow: `0px 0px 3px 0px  ${theme.palette.mode === "light" ? "black " : "white"}`,
                }}
                data-te-modal-dialog-ref>
                <div
                    className="pointer-events-auto relative flex w-full flex-col rounded-md border-none  bg-clip-padding text-current shadow-lg outline-none ">
                    <div
                        className="flex flex-shrink-0 items-center justify-between rounded-t-md  border-opacity-100 p-4 dark:border-opacity-50">
                        <Header title="Add Project" sx={{ color: `${colors === 'light' ? 'black' : 'white'}` }} />
                        <Box sx={{
                            backgroundColor: '#2068bf',
                            color: '#e0e0e0',
                            display: 'flex',
                            width: '40px',
                            borderRadius: '50%',
                            padding: '10px 10px',
                            alignContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                            onClick={() => setAddProjectToggle(!AddProjectToggle)}
                        >
                            < CloseIcon sx={{ mr: "10px" }} />
                        </Box>
                      
                    </div>
                    <div className="relative p-4">
                        <form>
                            <div className='flex flex-col h-[75vh]  max-sm:h-[70vh] overflow-auto'>
                                <Header title="Project Details" sx={{ color: `${colors === 'light' ? 'black' : 'white'}`, fontSize: "20px" }} />
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Project Name</label>
                                        <input
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}    h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name='projectname' onChange={handleInputChange} value={project.projectname}
                                        />
                                    </div>
                                     <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Project Owner</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name='projectowner' onChange={handleInputChange} value={project.projectowner}
                                        />
                                    </div>
                                     <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Tags</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name='tags' onChange={handleInputChange} value={project.tags}
                                        />
                                    </div>

                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                     <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Project Description</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name='projectdescription' onChange={handleInputChange} value={project.projectdescription}
                                        />
                                    </div>
                                     <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Start Date</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="date"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name='startdate' onChange={handleInputChange} value={project.startdate}

                                        />
                                    </div>
                                     <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Finish Date</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="date"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name='finishdate' onChange={handleInputChange} value={project.finishdate}

                                        />
                                    </div>
                                </div>
                                <Header title="Deal Details" sx={{ color: `${colors === 'light' ? 'black' : 'white'}`, fontSize: "20px" }} />
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                     <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Deal Sign Date</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="date"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name='dealsigndate' onChange={handleInputChange} value={project.dealsigndate}

                                        />
                                    </div>
                                     <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Deal Close Date</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="date"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name='dealclosedate' onChange={handleInputChange} value={project.dealclosedate}

                                        />
                                    </div>
                                     <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Deal Name</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name='dealname' onChange={handleInputChange} value={project.dealname}

                                        />
                                    </div>


                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                     <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Personnel</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name='personnel' onChange={handleInputChange} value={project.personnel}
                                        />
                                    </div>
                                     <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Deal Type</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name='dealtype' onChange={handleInputChange} value={project.dealtype}

                                        />
                                    </div>
                                     <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Market</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name='market' onChange={handleInputChange} value={project.market}

                                        />
                                    </div>
                                     <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Deal Value</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name='dealvalue' onChange={handleInputChange} value={project.dealvalue}

                                        />
                                    </div>

                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                    <div className="mb-4 mr-2 w-[100%]" >
                                        <label>Personnel Deal Description</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name='personneldealdescription' onChange={handleInputChange} value={project.personneldealdescription}

                                        />
                                    </div>
                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>

                                     <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Progress</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            name="progress" onChange={handleInputChange} value={project.phase}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Progress
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value={"On-Track"}>On-Track</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value={"Active"}>Active</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value={"InActive"}>InActive</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value={"Completed"}>Completed</option>
                                        </select>
                                    </div>
                                     <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Status</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            name='status' onChange={handleInputChange} value={project.status}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Status
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value={"Active"}>Active</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value={"InActive"}>InActive</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value={"Completed"}>Completed</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-center">
                                <Button type="submit" onClick={handelCreateproject} variant="contained" sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100], }} >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Addproject