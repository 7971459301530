import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box >
      <Box
        color={colors.grey[100]}
        fontWeight="bold"
        className="2xl:text-2xl xl:text-2xl lg:text-xl md:text-lg sm:text-[19px] text-[19px]"
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Box>
      <Box  color={colors.greenAccent[400]}>
        {subtitle}
      </Box>
    </Box>
  );
};

export default Header;
