import React from 'react'
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HelpIcon from '@mui/icons-material/Help';
const Setting = ({ openSetting, setopenSetting, setLoggedIn }) => {
    const theme = useTheme();
    return (
        <div className={`${openSetting ? "block" : "hidden"} z-10  absolute w-[100px] right-[120px] top-[50px] ${theme.palette.mode === "light" ? "bg-black" : "bg-white"}   rounded-xl`} style={{ boxShadow: '4px 0px 5px #484343' }}>
            <div className="">
                <ul>
                    <li>
                        <div className={`${theme.palette.mode === "light" ? "text-white" : "text-black"} text-center rounded  hover:bg-slate-400`}>
                        <IconButton
                                sx={{ borderRadius: "10px" }}
                            >
                                <HelpIcon sx={{ color: `${theme.palette.mode === "light" ? "white" : "black"}` ,marginRight:"5px" }} />
                                <span style={{ fontSize: "14px", marginLeft: "2px", color: `${theme.palette.mode === "light" ? "white" : "black"}` }}>Help?</span>
                            </IconButton>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
    )
}

export default Setting