// aws-exports.js
const awsconfig = {
    aws_project_region: 'eu-north-1',
    aws_cognito_identity_pool_id: 'eu-north-1:ca512bb5-983a-4957-99a3-2c293910e7aa',
    aws_cognito_region: 'eu-north-1',
    aws_user_pools_id: 'eu-north-1_NLvW8NnoQ',
    aws_user_pools_web_client_id: '3559i49ok8ro4iqei4im5s5v8u',
    oauth: {},
  };
  
  export default awsconfig;