import React, { useEffect } from 'react'
import { Box, Button } from "@mui/material";
import { tokens } from "../../theme"
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const AddRisk = ({ AddModalToggle, setAddModalToggle, risk, setrisk, projectData }) => {
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/risk';

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setrisk({
            ...risk,
            [name]: value,
        });
    };
    const uid = function () {
        return Date.now() + Math.floor(Math.random());
    }
    const userid = localStorage.getItem("user")

    useEffect(() => {
        setrisk((prevRisk) => ({
            ...prevRisk,
            id: uid(),
        }));
    }, [setrisk]);
    const handelAddRisk = async (e) => {
        e.preventDefault();
        // console.log(risk)
        try {
            const response = await fetch(lambdaEndpoint, {
                method: 'POST',
                headers: {
                    "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
                },
                body: JSON.stringify(risk),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // console.log('Response:', response);
            // console.log('Response Data:', data);
        } catch (error) {
            console.error('Error:', error);
        }
        setrisk({
            userid: userid,
            id: uid(),
            functions: "",
            projectname: "",
            riskdescritption: "",
            consequence: "",
            title: "",
            identifyby: "",
            owner: "",
            severity: "",
            likelihood: "",
            status: "",
            mitigation: "",
            notes: "",
            budget: "",
            residualrisk: "",
            riskstatus: ""
        })
        setAddModalToggle(!AddModalToggle)
    };

    return (
        <div
            data-te-modal-init
            className={` fixed left-0 top-0 z-[1055] ${AddModalToggle ? "block" : "hidden"} h-full w-full overflow-y-auto overflow-x-hidden outline-none`}
            id="exampleModalLg"
            tabIndex="-1"
            aria-labelledby="exampleModalLgLabel"
            aria-modal="true"
            role="dialog">
            <div
                className={`${theme.palette.mode === 'light' ? 'bg-[#eef0f2]' : 'bg-[#141b2d]'} 2xl:mt-[5px] 2xl:ml-[235px] 
                xl:mt-[5px] xl:ml-[150px] lg:mt-[5px] lg:ml-[100px] md:mt-[70px] md:ml-[70px] max-sm:mt-[50px] max-sm:ml-[50px] pointer-events-none  relative w-[80%] duration-300 ease-in-out`}
                style={{
                    boxShadow: `0px 0px 3px 0px  ${theme.palette.mode === "light" ? "black " : "white"}`,
                }}
                data-te-modal-dialog-ref>
                <div
                    className="pointer-events-auto relative flex w-full flex-col rounded-md border-none  bg-clip-padding text-current shadow-lg outline-none ">
                    <div
                        className="flex flex-shrink-0 items-center justify-between rounded-t-md  border-opacity-100 p-4 dark:border-opacity-50">
                        <Header title="Add Risk" sx={{ color: `${colors === 'light' ? 'black' : 'white'}` }} />
                        <Box sx={{
                            backgroundColor: '#2068bf',
                            color: '#e0e0e0',
                            display: 'flex',
                            width: '40px',
                            borderRadius: '50%',
                            padding: '10px 10px',
                            alignContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                            onClick={() => setAddModalToggle(!AddModalToggle)}
                        >
                            < CloseIcon sx={{ mr: "10px" }} />
                        </Box>
                    </div>
                    <div className="relative p-4">
                        <form>
                            <div className='flex flex-col h-[75vh]  max-sm:h-[70vh] overflow-auto'>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Function</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="functions"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={risk.functions}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Function
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Communications" >
                                                Communications
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Compliance" >
                                                Compliance
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Finance" >
                                                Finance
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="GSB-Customer Service" >
                                                GSB-Customer Service
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="GSB-Invoice To Cash" >
                                                GSB-Invoice To Cash
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="IT" >
                                                IT
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Legal" >
                                                Legal
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Market Access" >
                                                Market Access
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Prof. Ed." >
                                                Prof. Ed.
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Marketing" >
                                                Marketing
                                            </option>
                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Select Project</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="parentid"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={risk.projectname}
                                            onChange={(event) => {
                                                const selectedProject = event.target.value;
                                                // Update the task state with the selected milestone's ID
                                                setrisk({
                                                    ...risk,
                                                    projectname: selectedProject,
                                                });
                                            }}
                                        >
                                            <option
                                                className={`${theme.palette.mode === 'light'
                                                    ? 'bg-black text-white'
                                                    : 'bg-gray-500 text-black'
                                                    }`}
                                                value=""
                                                disabled
                                            >
                                                Select Project
                                            </option>
                                            {projectData?.length > 0 ? (
                                                projectData.map((project, index) => {
                                                    return (

                                                        <option
                                                            key={index}
                                                            className={`${theme.palette.mode === 'light'
                                                                ? 'bg-black text-white'
                                                                : 'bg-gray-500 text-black'
                                                                }`}
                                                            value={project.projectname}
                                                        >
                                                            {project.projectname}
                                                        </option>
                                                    )

                                                })
                                            ) : (
                                                <option value="" disabled>
                                                    No Projects are available
                                                </option>
                                            )}


                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Risk Description</label>
                                        <input
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}    h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="riskdescritption"
                                            value={risk.riskdescritption}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Consequence of Risk</label>
                                        <input
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}    h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="consequence"
                                            value={risk.consequence}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Risk Name</label>
                                        <input
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}    h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="title"
                                            value={risk.title}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Identify By</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}

                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="identifyby"
                                            value={risk.identifyby}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Owner</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="owner"
                                            value={risk.owner}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Severity of potential failure</label>
                                        <select

                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder='Severity of potential failure'
                                            name="severity"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={risk.severity}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Severity
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Low">Low</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Medium">Medium</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="High">High</option>

                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Likelihood of Potential failure</label>
                                        <select

                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder='Likelihood of Potential failure'
                                            name="likelihood"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={risk.likelihood}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Likelihood
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Low">Low</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Medium">Medium</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="High">High</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Risk Grade</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder='Risk Grade'
                                            name="status"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={risk.status}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Risk Grade
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Low">Low</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Medium">Medium</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="High">High</option>
                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Mitigation Activity / Contingency Plan</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}

                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="mitigation"
                                            value={risk.mitigation}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Notes</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}

                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="notes"
                                            value={risk.notes}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Budget</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}

                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="budget"
                                            value={risk.budget}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Residual Risk</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="residualrisk"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={risk.residualrisk}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Residual Risk
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="No">No</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Yes">Yes</option>
                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Risk Status</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="riskstatus"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={risk.riskstatus}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Risk Status
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Monitor">Monitor</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Closed">Closed</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-center">
                                <Button type="submit" onClick={handelAddRisk} variant="contained" sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100], }} >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddRisk
