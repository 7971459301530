import React, { useEffect } from 'react'
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from '../../theme';
import { Link, useLocation } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Header from '../../components/Header';
import { MultipleStopOutlined } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import BusinessIcon from '@mui/icons-material/Business';

const Tracker = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path;
  };
  useEffect(() => {
    document.title = "iDeal - Dependencies Tracker";
  }, [])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const columns = [
    {
      field: "function",
      headerName: "Function",
      flex: 1,


    },
    {
      field: "noofdependencies",
      headerName: "No of Dependencies",
      flex: 1,


    },
    {
      field: "interlockeddependencies",
      headerName: "Interlocked Dependencies",
      flex: 1,
    },
    {
      field: "noninterlockeddependencies",
      headerName: "Non - Interlocked Dependencies",
      flex: 1,

    },
    {
      field: "viewDependencies",
      headerName: "View Dependencies",
      flex: 1,

    },
  ];
  const rows = [
    {
      id: 0,
      function: "SOPS",
      noofdependencies: 25,
      interlockeddependencies: 25,
      noninterlockeddependencies: 5,

    },
    {
      id: 1,
      function: "Analysis",
      noofdependencies: 10,
      interlockeddependencies: 8,
      noninterlockeddependencies: 2,

    },
    {
      id: 2,
      function: "Development",
      noofdependencies: 15,
      interlockeddependencies: 12,
      noninterlockeddependencies: 3,
    },
    {
      id: 3,
      function: "Testing",
      noofdependencies: 8,
      interlockeddependencies: 6,
      noninterlockeddependencies: 2,

    },
    {
      id: 4,
      function: "Design",
      noofdependencies: 12,
      interlockeddependencies: 10,
      noninterlockeddependencies: 2,

    },
    {
      id: 5,
      function: "Design",
      noofdependencies: 12,
      interlockeddependencies: 10,
      noninterlockeddependencies: 2,

    },
    {
      id: 6,
      function: "Design",
      noofdependencies: 12,
      interlockeddependencies: 10,
      noninterlockeddependencies: 2,

    },
  ];
  const handelGoBack = () => {
    window.history.back()
  }

  return (
    <Box m="10px">
      <Box >
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <BusinessIcon className={`mr-2 text-xs  ${isActive('/dependencies') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'}`} />
              <Link
                style={{
                  fontWeight: '700',
                  fontFamily: 'sans-serif'
                }}
                to="/dependencies"
                className={`inline-flex items-center text-sm font-medium ${isActive('/dependencies') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600`}
              >
                Dependencies
              </Link>
            </li>
            <li className="inline-flex items-center">
              <MultipleStopOutlined className={`mr-2 text-xs  ${isActive('/dependencies/tracker') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'}`} />
              <Link
                style={{
                  fontWeight: '700',
                  fontFamily: 'sans-serif'
                }}
                to="/dependencies/tracker"
                className={`inline-flex items-center text-sm font-medium ${isActive('/dependencies/tracker') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600`}
              >
                Tracker
              </Link>
            </li>
            <li>
              <div className="flex items-center">
                <svg
                  className="w-3 h-3 text-gray-400 mx-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
                <VpnKeyIcon className={`mr-2 text-xs  ${isActive('/dependencies/log') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'}`} />
                <Link
                  style={{
                    fontWeight: '700',
                    fontFamily: 'sans-serif'
                  }}
                  to="/dependencies/log"
                  className={`inline-flex items-center text-sm font-medium ${isActive('/dependencies/log') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600 `}
                >
                  Log
                </Link>
              </div>
            </li>

          </ol>
        </nav>
      </Box>
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="20px" marginBottom={"10px"}>
        <Header title="DEPENDENCIES TRACKER" />
        <Box>
          <button
            className="hover:bg-transparent"
            style={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              padding: "9px 20px",
              zIndex: "-1",
              borderRadius: "4px",
              lineHeight: "1.75rem"
            }}
            onClick={handelGoBack}
          >
            <KeyboardBackspaceIcon sx={{ mr: "10px" }} />
            <span className="2xl:block xl:block lg:block md:block sm:hidden hidden"> BACK </span>
          </button>
        </Box>
      </Box>
      <Box sx={{
        width: "100%",
        display: "flex",
        boxShadow: `0px 0px 3px 0px  ${theme.palette.mode === "light" ? "black " : "white"}`
      }}>
        <Box
          backgroundColor={colors.primary[400]}
          className="h-[150px] max-sm:h-[120px] w-[25%] flex items-center justify-center flex-col hover:bg-[#303643]"
        >
          <Button>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ color: colors.grey[100] }}>
              <div style={{ display: "flex", alignItems: "center" }}> <MultipleStopOutlined sx={{ fontSize: "30px", color: "red" }} /><span className="2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[20px] sm:text-[18px] text-[16px] ml-[5px]">25</span></div>
              <Box className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[16px] sm:text-[12px] text-[10px]" sx={{ m: "4px 0 0 0" }} >Total Dependency</Box>
            </Box>
          </Button>
        </Box>
        <Box
       backgroundColor={colors.primary[400]}
       className="h-[150px] max-sm:h-[120px] w-[25%] flex items-center justify-center flex-col hover:bg-[#303643]"
        >
          <Button>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ color: colors.grey[100] }}>
              <div style={{ display: "flex", alignItems: "center" }}> <MultipleStopOutlined sx={{ fontSize: "30px", color: "red" }} /><span className="2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[20px] sm:text-[18px] text-[16px] ml-[5px]">2</span></div>
              <Box className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[16px] sm:text-[12px] text-[10px]"  >Overdue Dependency</Box>
            </Box>
          </Button>
        </Box>
        <Box
      backgroundColor={colors.primary[400]}
      className="h-[150px] max-sm:h-[120px] w-[25%] flex items-center justify-center flex-col hover:bg-[#303643]"
        >
          <Button>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ color: colors.grey[100] }}>
              <div style={{ display: "flex", alignItems: "center" }}> <MultipleStopOutlined sx={{ fontSize: "30px", color: "red" }} /><span className="2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[20px] sm:text-[18px] text-[16px] ml-[5px]">10</span></div>
              <Box className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[16px] sm:text-[12px] text-[10px]" sx={{ m: "4px 0 0 0" }} >Interlock Dependency</Box>
            </Box>
          </Button>
        </Box>
        <Box
      backgroundColor={colors.primary[400]}
      className="h-[150px] max-sm:h-[120px] w-[25%] flex items-center justify-center flex-col hover:bg-[#303643]"
        >
          <Button>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ color: colors.grey[100] }}>
              <div style={{ display: "flex", alignItems: "center" }}> <MultipleStopOutlined sx={{ fontSize: "30px", color: "red" }} /><span className="2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[20px] sm:text-[18px] text-[16px] ml-[5px]">15</span></div>
              <Box className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[16px] sm:text-[12px] text-[10px]" sx={{ m: "4px 0 0 0" }} >Non Interlock Dependency</Box>
            </Box>
          </Button>
        </Box>
        <Box
      backgroundColor={colors.primary[400]}
      className="h-[150px] max-sm:h-[120px] w-[25%] flex items-center justify-center flex-col hover:bg-[#303643]"
        >
          <Button>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ color: colors.grey[100] }}>
              <div style={{ display: "flex", alignItems: "center" }}> <MultipleStopOutlined sx={{ fontSize: "30px", color: "red" }} /><span className="2xl:text-[25px] xl:text-[25px] lg:text-[25px] md:text-[20px] sm:text-[18px] text-[16px] ml-[5px]">1</span></div>
              <Box className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[16px] sm:text-[12px] text-[10px]" sx={{ m: "4px 0 0 0" }} >Completed Interlock</Box>
            </Box>
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: "flex", width: "100%", marginTop: "20px" }}>
        <Box className="w-[75%] max-sm:w-[100%]" sx={{  boxShadow: `0px 0px 3px 0px  ${theme.palette.mode === "light" ? "black " : "white"}` }}>
          <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: "0px" }}>
            <TableContainer  >
              <Table stickyHeader aria-label="sticky table">
                <TableHead >
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.function}
                        sx={{ backgroundColor: `${theme.palette.mode === "light" ? "#0393fb" : "#2068bf"}` }}
                      >
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover key={index} >
                          <TableCell sx={{ textAlign: "center", padding: "5px 16px" }} >
                            {row.function}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", padding: "5px 16px" }}>
                            {row.noofdependencies}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", padding: "5px 16px" }}>
                            {row.interlockeddependencies}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", padding: "5px 16px" }}>
                            {row.noninterlockeddependencies}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", padding: "5px 16px" }} >
                            <Button
                              sx={{
                                color: colors.blueAccent[700],
                                fontSize: "10px",
                                fontWeight: "bold",
                                textDecoration: "underline",
                              }}
                            // onClick={() => handleViewTask(params.row.id)}
                            >
                              {/* < AddTaskIcon sx={{ mr: "10px" }} /> */}
                              View Task
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
        <Box className="max-sm:hidden " sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "25%", margin: "5px 0px", marginLeft: "15px", backgroundColor: `${theme.palette.mode === "light" ? "white" : "#1f2a40"}`, boxShadow: `0px 0px 3px 0px  ${theme.palette.mode === "light" ? "black " : "white"}` }}>
          <Box sx={{ color: `${theme.palette.mode === "light" ? "black" : "white"}`, backgroundColor: `${theme.palette.mode === "light" ? "white" : "#1f2a40"}`, height: "50%", }}>
            Overdue Dependencies
          </Box>
          <Box sx={{ color: `${theme.palette.mode === "light" ? "black" : "white"}`, backgroundColor: `${theme.palette.mode === "light" ? "white" : "#1f2a40"}`, height: "50%", }}>
            Non Interlock Dependencies
          </Box>
        </Box>
      </Box>

    </Box >
  )
}

export default Tracker