import { Box, Button, Typography } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import playstore from "../../images/play-store.png";
import appstore from "../../images/app-store.png"
import Toaster from "../../Toastify/Toaster";
import { toast } from 'react-toastify';
import { Amplify } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
const Login = ({ setLoggedIn, loggedIn }) => {
  const navigate = useNavigate();
  const defaultTheme = createTheme();
  const  {currentUser,setcurrentUser} = useAuth()
  useEffect(() => {
    document.title = "iDeal - Login";
  }, [])
  Amplify.configure({
    Auth: {
      region: 'eu-north-1',
      userPoolId: 'eu-north-1_NLvW8NnoQ',
      userPoolWebClientId: '3559i49ok8ro4iqei4im5s5v8u',
    },
  });
  const [isLoading, setisLoading] = useState(false)
  const handleSubmit = async (event) => {
    event.preventDefault();
    setisLoading(true);
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');
    const password = formData.get('password');

    if (!email && !password) {
      toast.error('Email and Password are required', { theme: 'light' });
    } else if (!email) {
      toast.error('Email cannot be empty', { theme: 'light' });
    } else if (!password) {
      toast.error('Password cannot be empty', { theme: 'light' });
    } else {
      try {
        const user = await Auth.signIn(email, password);
        //console.log( "user is" ,  user)
        localStorage.setItem('username',user.username);
        setcurrentUser(user.attributes.profile)
        localStorage.setItem('user', user.attributes.profile);
        localStorage.setItem('Token', user.signInUserSession.accessToken.jwtToken);
        toast.success('You are successfully logged in', { theme: 'light' });
        setTimeout(() => {
          navigate('/');
        }, 1000);
      } catch (error) {
        toast.error('Invalid Credentials', { theme: 'light' });
        console.error('Error logging in:', error);
      }
    }

    setisLoading(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Toaster />
      <Grid container component="main" className="overflow-hidden h-screen w-screen">
        <CssBaseline />
        <Grid className="max-sm:hidden w-[50%] flex items-center justify-center" >
          <Typography component="h1" variant="h5" sx={{ fontSize: "2rem" }}  >
            <span style={{ color: "blue" }} >i</span>Deal
          </Typography>
        </Grid> 
        <Grid className="h-[100%] max-sm:w-[100%] w-[35%] flex flex-col items-center justify-center max-sm:m-7">
          <CssBaseline />
          <Box
            sx={{
              padding: "30px",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: "whitesmoke",
              borderRadius: "3%",
              boxShadow: '0px 0px 15px 0px rgb(15 82 234 / 90%)',
            }}
          >
            <Typography component="h1" variant="h5" >
              Welcome to  <span style={{ color: "blue" }}>i</span>Deal
            </Typography>
            <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder="Email ID"
                name="email"
                autoComplete="email"
                autoFocus
                // sx={{padding:"8.5px 7px"}}
                InputProps={{
                  endAdornment: <EmailIcon className="text-blue-700" />,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                placeholder="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                // sx={{padding:"8.5px 7px"}}
                InputProps={{
                  endAdornment: <LockIcon className="text-blue-700" />,
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {isLoading && <div role="status">
                  <svg aria-hidden="true" className="inline w-5 h-5 mr-2 text-black-200 animate-spin dark:text-black-600 fill-white-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>} Login
              </Button>
              <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Grid >
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <hr
              style={{
                border: '1px solid #848383',
                width: '90%'
              }}
            />
            <Typography component="h1" variant="h5" sx={{ fontSize: "1.2rem", display: "flex", alignItems: "center" }} >
              Also Available on <div style={{ display: "flex" }}>
                <img src={playstore} alt="playstore" style={{ width: "50px" }} />
                <img src={appstore} alt="appstore" style={{ width: "50px" }} />
              </div>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  )

};

export default Login;
