import React, { useState ,useEffect } from 'react';
import { Box, Button, Container, TextField,  Grid, MenuItem, FormControl, useTheme, FormLabel } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { Link, useNavigate } from 'react-router-dom';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const AddProjectPage = () => {
  const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/project';
  const navigate = useNavigate()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const userid = localStorage.getItem("user")
  const uid = function () {
    return Date.now() + Math.floor(Math.random());
  }
  useEffect(() => {
    document.title = "iDeal - AddProject"; 
  },[])
  const [project, setproject] = useState({
    userid:userid,
    id:uid(),
    projectname: "",
    projectowner: "",
    tags: "",
    projectdescription: "",
    startdate: "",
    finishdate: "",
    dealsigndate: "",
    dealclosedate: "",
    dealname: "",
    personnel: "",
    dealtype: "",
    market: "",
    dealvalue: "",
    personneldealdescription: "",
    progress: "",
    status: "",
  })
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setproject({
      ...project,
      [name]: value,
    });
  };
  
 
  useEffect(() => {
    setproject((prevproject) => ({
      ...prevproject,
      id: uid(),
    }));
  }, [setproject])
  const handelCreateproject = async (e) => {
    e.preventDefault();
    //console.log(project)
    try {
        const response = await fetch(lambdaEndpoint, {
            method: 'POST',
            headers: {
                "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
            },
            body: JSON.stringify(project),
        });
        const data = await response.json();
        //console.log('Response:', response);
        //console.log('Response Data:', data);
    } catch (error) {
        console.error('Error:', error);
    }
    setproject({
      userid:userid,
      id:uid(),
      projectname: "",
      projectowner: "",
      tags: "",
      projectdescription: "",
      startdate: "",
      finishdate: "",
      dealsigndate: "",
      dealclosedate: "",
      dealname: "",
      personnel: "",
      dealtype: "",
      market: "",
      dealvalue: "",
      personneldealdescription: "",
      progress: "",
      status: "",
    })
    navigate("/")
  }
  return (
    <Box m="20px">
      <Container >
        <Box display="flex" justifyContent="space-between" alignItems="center" >
          <Header title="Create Project" />
          <Box>
            <Link to="/projects/active">
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  zIndex: "-1",
                  Margin: "0 30px"
                }}
              >
                <KeyboardBackspaceIcon sx={{ mr: "10px" }} />
                BACK
              </Button>
            </Link>
          </Box>
        </Box>
        <FormControl>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField id='input' sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} placeholder="Project Name" fullWidth margin="normal"  required name='projectname' onChange={handleInputChange} value={project.projectname} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField placeholder="Project Owner" margin="normal" fullWidth sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} name='projectowner' onChange={handleInputChange} value={project.projectowner} />
            </Grid>
            <Grid item xs={12} >

              <TextField placeholder="Tags" margin="normal" fullWidth sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} name='tags' onChange={handleInputChange} value={project.tags} />
            </Grid>
            <Grid item xs={12}>
              <TextField sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} placeholder="Project Description" multiline rows={4} fullWidth margin="normal" name='projectdescription' onChange={handleInputChange} value={project.projectdescription} />
            </Grid>
            <Grid item xs={6}>
              <FormLabel sx={{ color: ` ${theme.palette.mode === 'light' ? 'black' : 'white'}` }}>Start Date</FormLabel>
              <TextField sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} placeholder="Start Date" type="date" fullWidth margin="normal" name='startdate' onChange={handleInputChange} value={project.startdate} />
            </Grid>
            <Grid item xs={6}>
              <FormLabel sx={{ color: ` ${theme.palette.mode === 'light' ? 'black' : 'white'}` }}>Finish Date</FormLabel>
              <TextField sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} placeholder="Finish Date" type="date" fullWidth margin="normal" name='finishdate' onChange={handleInputChange} value={project.finishdate} />
            </Grid>
            <Grid item xs={12}>
              <div >
                <Header title="Deals Details" />
                <hr style={{ borderColor: 'gray' }} />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel sx={{ color: ` ${theme.palette.mode === 'light' ? 'black' : 'white'}` }}>Deal Sign Date</FormLabel>
              <TextField sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} placeholder="Deal Sign Date" type="date" fullWidth margin="normal" name='dealsigndate' onChange={handleInputChange} value={project.dealsigndate} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel sx={{ color: ` ${theme.palette.mode === 'light' ? 'black' : 'white'}` }}>Deal Close Date</FormLabel>
              <TextField sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} placeholder="Deal Close Date" type="date" fullWidth margin="normal" name='dealclosedate' onChange={handleInputChange} value={project.dealclosedate} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} placeholder="Deal Name" fullWidth margin="normal" name='dealname' onChange={handleInputChange} value={project.dealname} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} placeholder="Personnel" fullWidth margin="normal" name='personnel' onChange={handleInputChange} value={project.personnel} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} placeholder="Deal Type" fullWidth margin="normal" name='dealtype' onChange={handleInputChange} value={project.dealtype} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} placeholder="Market" fullWidth margin="normal" name='market' onChange={handleInputChange} value={project.market} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} placeholder="Deal Value" fullWidth margin="normal" name='dealvalue' onChange={handleInputChange} value={project.dealvalue} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} placeholder="Personnel Deal Description" multiline fullWidth margin="normal" name='personneldealdescription' onChange={handleInputChange} value={project.personneldealdescription} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} placeholder="Progress" fullWidth margin="normal" >
                <InputLabel id="demo-multiple-name-label">Progress</InputLabel>
                <Select
                  name='progress' 
                  onChange={handleInputChange} value={project.progress}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  input={<OutlinedInput label="Name" />}
                >
                  <MenuItem value={"On-Track"}>On-Track</MenuItem>
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"InActive"}>InActive</MenuItem>
                  <MenuItem value={"Completed"}>Completed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}` }} placeholder="Status" fullWidth margin="normal">
                <InputLabel id="demo-multiple-name-label">Status</InputLabel>
                <Select
                  name='status'
                   onChange={handleInputChange} value={project.status}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  input={<OutlinedInput label="Name" />}
                >
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"InActive"}>InActive</MenuItem>
                  <MenuItem value={"Completed"}>Completed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box mt={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button variant="contained" onClick={handelCreateproject}>
              Create Project
            </Button>
            <Link to="/projects/active"><Button sx={{ border: `1px solid ${theme.palette.mode === 'light' ? 'black' : 'white'}`, color: `${theme.palette.mode === 'light' ? 'black' : 'white'}`, marginLeft: "10px" }} variant="outlined">
              Cancel
            </Button></Link>
          </Box>
        </FormControl>
      </Container>
    </Box>
  );
};

export default AddProjectPage;
