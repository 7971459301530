import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import Notification from "../../pannel/notification";
import User from "../../pannel/user";
import Setting from "../../pannel/setting";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import RowingOutlined from '@mui/icons-material/RowingOutlined';
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import {
  DoneAllOutlined, AssignmentTurnedInOutlined, StreamOutlined, RuleOutlined, PrivacyTipOutlined, MultipleStopOutlined, BugReportOutlined, AssignmentOutlined, TourOutlined,
} from "@mui/icons-material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AWS from 'aws-sdk';


AWS.config.update({
    region: 'eu-north-1',
    accessKeyId: 'AKIA6AWA27EBIZT3FY6W',
    secretAccessKey: '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',
});
const s3 = new AWS.S3();
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Topbar = ({ setLoggedIn, Getnofication, notification, setnotification }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [opennotification, setopennotification] = useState(false)
  const [openUserPannel, setopenUserPannel] = useState(false)
  const [openProject, setopenProject] = useState(false)
  const [openWorkplan, setopenWorkplan] = useState(false)
  const [openDependecies, setopenDependecies] = useState(false)
  const [openRaid, setopenRaid] = useState(false)
  const [openSetting, setopenSetting] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");
  const [isHover, setisHover] = useState(false)
  const [CurrentUserImage, setCurrentUserImage] = useState("")
  const [isLoading, setisLoading] = useState(false)

  const GetUserImage = async () => {
    setisLoading(true);
    const username = localStorage.getItem("username")
    const params = {
      Bucket:'idealuserimage',
      Key: username,
    };
    s3.getObject(params, (err, data) => {
      if (err) {
        console.error('Error retrieving object:', err);
      } else {
        // The object data is in the "Body" property of the response
        const objectData = data.Body.toString('utf-8'); // Assuming it's a text file
        //console.log('Object data:', objectData);
        setCurrentUserImage(objectData)
      }
    });

    setisLoading(false)
  };
  useEffect(() => {
    const username = localStorage.getItem("username");
    GetUserImage();
  }, [])
  const navigate = useNavigate();
  const userid = localStorage.getItem('user');
  const handelLogout = () => {
    setLoggedIn(false)
    localStorage.removeItem("Token")
    localStorage.removeItem("user")
    navigate("/login")
  }

  const handleNotification = () => {
    Getnofication()
    setopennotification(!opennotification)
    setopenUserPannel(false)
    setopenSetting(false)
  }
  const handelUserPannel = () => {
    setopenUserPannel(!openUserPannel)
    setopennotification(false)
    setopenSetting(false)
  }

  const unread = Array.isArray(notification) && notification.filter(notify => !notify.isRead).length;
  useEffect(() => {
    Getnofication()
  }, [])
  return (
    <>
      {!isCollapsed && <Box
        className="h-screen absolute"
        sx={{
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            padding: "14px 25px 5px 16px !important",
          },
          "& .pro-inner-item:hover": {
            color: "#868dfb !important",
          },
          "& .pro-menu-item.active": {
            color: "#6870fa !important",
          },
        }}
      >
        <ProSidebar collapsed={isCollapsed} style={{ height: "100vh" }}>
          <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "0 0 0 0",
                color: colors.grey[100],
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <Typography variant="h1" color={colors.grey[100]} >
                    <span className="text-blue-700">i</span>
                    Deal
                  </Typography>
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            {!isCollapsed && (
              <Box mb="2px">
                <Box display="flex" justifyContent="center" alignItems="center">
                </Box>
                <Box textAlign="center">
                </Box>
              </Box>
            )}

            <Box paddingLeft={isCollapsed ? "" : "3%"}>
              {!isCollapsed && <Item
                title="PROJECTS"
                to="/"
                selected={selected}
                setSelected={setSelected}
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                PROJECTS
              </Item>}

              <Item
                title="Active"
                to="/projects/active"
                icon={<AssignmentOutlined />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Completed"
                to="/projects/completed"
                icon={<AssignmentTurnedInOutlined />}
                selected={selected}
                setSelected={setSelected}
              />


              {!isCollapsed && <Item
                title={"WORKPLAN"}
                to="/workplan"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
                selected={selected}
                setSelected={setSelected}
              />}
              <Item
                title="Tasks"
                to="/task"
                icon={<DoneAllOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Milestones"
                to="/milestone"
                icon={<TourOutlined />}
                selected={selected}
                setSelected={setSelected}
              />

              {!isCollapsed && <Item
                title={"DEPENDENCIES"}
                to="/dependencies"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
                selected={selected}
                setSelected={setSelected}
              />}


              <Item
                title="Tracker"
                to="/dependencies/tracker"
                icon={<MultipleStopOutlined />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Log"
                to="/dependencies/log"
                icon={<VpnKeyIcon />}
                selected={selected}
                setSelected={setSelected}
              />


              {!isCollapsed && <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                RAID LOG
              </Typography>}
              <Item
                title="Risk"
                to="/risk"
                icon={<PrivacyTipOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Actions"
                to="/actions"
                icon={<RowingOutlined />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Issues"
                to="/issues"
                icon={<BugReportOutlined />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Decision"
                to="/decision"
                icon={<StreamOutlined />}
                selected={selected}
                setSelected={setSelected}
              />

            </Box>
          </Menu>
        </ProSidebar>
      </Box>}
      <Box className="flex justify-between items-center p-2 h-[10vh]">
        <Box className="2xl:hidden xl:hidden lg:hidden md:block sm:block block">
          <Box display="flex">
            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <MenuOutlinedIcon />
            </IconButton>
            <Typography variant="h1" color={colors.grey[100]} >
              <span className="text-blue-700">i</span>
              Deal
            </Typography>
          </Box>
        </Box>
        <Box className="max-md:hidden">
          <Box style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '20px',
            width: "100%"
          }}
          >
            <Box
              display="flex"
              backgroundColor={colors.primary[400]}
              borderRadius="3px"
              marginRight="10px"
              width="100%"
            >
              <InputBase sx={{ ml: 2, width: "100%" }} placeholder="Search" />
              <IconButton type="button" sx={{ p: 1 }}>
                <SearchIcon sx={{ color: `${theme.palette.mode === "light" ? "black" : "white"}` }} />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <span style={{
          fontSize: '20px',
          fontFamily: 'sans-serif'
        }} className="max-md:hidden">{userid}</span>


        <Box style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '20px'
        }}
        >
          <IconButton onClick={colorMode.toggleColorMode} sx={{ borderRadius: "10px" }}>
            {theme.palette.mode === "dark" ? (
              <>
                <DarkModeOutlinedIcon sx={{ color: `${theme.palette.mode === "light" ? "black" : "white"}` }} />
                <span className="max-md:hidden" style={{ fontSize: "12px", marginLeft: "2px", color: `${theme.palette.mode === "light" ? "black" : "white"}`, fontFamily: 'sans-serif' }}>Dark Mode</span>
              </>
            ) : (
              <>
                <LightModeOutlinedIcon sx={{ color: `${theme.palette.mode === "light" ? "black" : "white"}` }} />
                <span className="max-md:hidden" style={{ fontSize: "12px", marginLeft: "2px", color: `${theme.palette.mode === "light" ? "black" : "white"}`, fontFamily: 'sans-serif' }}>Light Mode</span>
              </>
            )}
          </IconButton>
          <IconButton sx={{ borderRadius: "10px" }} onClick={handleNotification}>
            {unread > 0 && <div className="notification-dot max-md:left-[20px] max-md:top-[10px]">
              <span className="dot">
                {unread}
              </span>
            </div>}
            <NotificationsOutlinedIcon sx={{ color: `${theme.palette.mode === "light" ? "black" : "white"}` }} />
            <span className="max-md:hidden" style={{ fontSize: "12px", marginLeft: "2px", color: `${theme.palette.mode === "light" ? "black" : "white"}`, fontFamily: 'sans-serif' }}>Notification</span>
          </IconButton>
          <IconButton sx={{ borderRadius: "10px" }} onClick={handelUserPannel} >
            <div className="relative w-[2.5rem] h-[2.5rem] overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              {CurrentUserImage ?
                <img src={CurrentUserImage} alt="User Img" className="absolute w-[2.5rem] h-[2.5rem] text-gray-400 -left-1" />
                : <svg className="absolute w-12 h-12 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>}
            </div>
            {/* <PersonOutlinedIcon sx={{ color: `${theme.palette.mode === "light" ? "black" : "white"}` }} />
            <span style={{ fontSize: "12px", marginLeft: "2px", color: `${theme.palette.mode === "light" ? "black" : "white"}` }}>User</span>
            <ArrowDropDownIcon sx={{ marginLeft: "2px" }} /> */}
          </IconButton>
        </Box>
      </Box>
      {opennotification && <Notification notification={notification} opennotification={opennotification} setopennotification={setopennotification} />}
      {openUserPannel && <User openUserPannel={openUserPannel} setopenUserPannel={setopenUserPannel} CurrentUserImage={CurrentUserImage} GetUserImage={GetUserImage} />}
      {openSetting && <Setting openSetting={openSetting} setopenSetting={setopenSetting} />}
    </>
  );
};




export default Topbar;
