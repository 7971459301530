import React, { useEffect } from 'react'
import { Box, Button } from "@mui/material";
import { tokens } from "../../theme"
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const AddAction = ({ action, setaction, AddModalToggle, setAddModalToggle, projectData }) => {
    const theme = useTheme();
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/action';
    const colors = tokens(theme.palette.mode);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setaction({
            ...action,
            [name]: value,
        });
    };
    const uid = function () {
        return Date.now() + Math.floor(Math.random());
    }
    const userid = localStorage.getItem("user")
    useEffect(() => {
        setaction((prevAction) => ({
            ...prevAction,
            id: uid(),
        }));
    }, [setaction]);
    const handelAddAction = async (e) => {
        e.preventDefault();
        // console.log(action)
        try {
            const response = await fetch(lambdaEndpoint, {
                method: 'POST',
                headers: {
                    "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
                },
                body: JSON.stringify(action),
            });
            const data = await response.json();
            // console.log('Response:', response);
            // console.log('Response Data:', data);
        } catch (error) {
            console.error('Error:', error);
        }
        setaction({
            userid: userid,
            id: uid(),
            date: "",
            projectname: "",
            title: "",
            primaryfunctions: "",
            secondaryfunctions: "",
            location: "",
            raid: "",
            topic: "",
            description: "",
            source: "",
            raisedby: "",
            owner: "",
            tmopoc: "",
            duedate: "",
            status: "",
            priority: "",
            comments: "",
            otcneeded: "",
            otccritical: "",
            hypercarecritical: ""
        })
        setAddModalToggle(!AddModalToggle)
    }
    return (
        <div
            data-te-modal-init
            className={` fixed left-0 top-0 z-[1055] ${AddModalToggle ? "block" : "hidden"} h-full w-full overflow-y-auto overflow-x-hidden outline-none`}
            id="exampleModalLg"
            tabIndex="-1"
            aria-labelledby="exampleModalLgLabel"
            aria-modal="true"
            role="dialog">
            <div
                className={`${theme.palette.mode === 'light' ? 'bg-[#eef0f2]' : 'bg-[#141b2d]'} 2xl:mt-[5px] 2xl:ml-[235px] 
                xl:mt-[5px] xl:ml-[150px] lg:mt-[5px] lg:ml-[100px] md:mt-[70px] md:ml-[70px] max-sm:mt-[50px] max-sm:ml-[50px] pointer-events-none  relative w-[80%] duration-300 ease-in-out`}
                style={{
                    boxShadow: `0px 0px 3px 0px  ${theme.palette.mode === "light" ? "black " : "white"}`,
                }}
                data-te-modal-dialog-ref>
                <div
                    className="pointer-events-auto relative flex w-full flex-col rounded-md border-none  bg-clip-padding text-current shadow-lg outline-none ">
                    <div
                        className="flex flex-shrink-0 items-center justify-between rounded-t-md  border-opacity-100 p-4 dark:border-opacity-50">
                        <Header title="Add Action" sx={{ color: `${colors === 'light' ? 'black' : 'white'}` }} />
                        <Box sx={{
                            backgroundColor: '#2068bf',
                            color: '#e0e0e0',
                            display: 'flex',
                            width: '40px',
                            borderRadius: '50%',
                            padding: '10px 10px',
                            alignContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                            onClick={() => setAddModalToggle(!AddModalToggle)}
                        >
                            < CloseIcon sx={{ mr: "10px" }} />
                        </Box>
                    </div>
                    <div className="relative p-4 ">
                        <form>
                            <div className='flex flex-col h-[75vh]  max-sm:h-[70vh] overflow-auto'>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Date</label>
                                        <input
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder='Date'
                                            type="date"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}    h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="date"
                                            value={action.date}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Select Project</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="parentid"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={action.projectname}
                                            onChange={(event) => {
                                                const selectedProject = event.target.value;
                                                // Update the task state with the selected milestone's ID
                                                setaction({
                                                    ...action,
                                                    projectname: selectedProject,
                                                });
                                            }}
                                        >
                                            <option
                                                className={`${theme.palette.mode === 'light'
                                                    ? 'bg-black text-white'
                                                    : 'bg-gray-500 text-black'
                                                    }`}
                                                value=""
                                                disabled
                                            >
                                                Select Project
                                            </option>
                                            {projectData?.length > 0 ? (
                                                projectData.map((project, index) => {
                                                    return (

                                                        <option
                                                            key={index}
                                                            className={`${theme.palette.mode === 'light'
                                                                ? 'bg-black text-white'
                                                                : 'bg-gray-500 text-black'
                                                                }`}
                                                            value={project.projectname}
                                                        >
                                                            {project.projectname}
                                                        </option>
                                                    )

                                                })
                                            ) : (
                                                <option value="" disabled>
                                                    No Projects are available
                                                </option>
                                            )}


                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Decision Title</label>
                                        <input
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}    h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="title"
                                            value={action.title}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Primary Function</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="primaryfunctions"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={action.primaryfunctions}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Primary Function
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Function
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Communications" >
                                                Communications
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Compliance" >
                                                Compliance
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Finance" >
                                                Finance
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="GSB-Customer Service" >
                                                GSB-Customer Service
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="GSB-Invoice To Cash" >
                                                GSB-Invoice To Cash
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="IT" >
                                                IT
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Legal" >
                                                Legal
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Market Access" >
                                                Market Access
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Prof. Ed." >
                                                Prof. Ed.
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Marketing" >
                                                Marketing
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>

                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Secondary Function</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="secondaryfunctions"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={action.secondaryfunctions}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Secondary Function
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Function
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Communications" >
                                                Communications
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Compliance" >
                                                Compliance
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Finance" >
                                                Finance
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="GSB-Customer Service" >
                                                GSB-Customer Service
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="GSB-Invoice To Cash" >
                                                GSB-Invoice To Cash
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="IT" >
                                                IT
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Legal" >
                                                Legal
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Market Access" >
                                                Market Access
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Prof. Ed." >
                                                Prof. Ed.
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Marketing" >
                                                Marketing
                                            </option>
                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Location</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="location"
                                            value={action.location}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>RAID</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="raid"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={action.raid}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Raid
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Action">Action</option>

                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Topic</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="topic"
                                            value={action.topic}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>

                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Description</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="description"
                                            value={action.description}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Source</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="source"
                                            value={action.source}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Raised By</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="raisedby"
                                            value={action.raisedby}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Owner</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="owner"
                                            value={action.owner}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>TMO POC</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="tmopoc"
                                            value={action.tmopoc}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Due Date</label>
                                        <input
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            type="date"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}    h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="duedate"
                                            value={action.duedate}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Status</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder='Status'
                                            name="status"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={action.status}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Status
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Open">Open</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="In">In Progress</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Close">Close</option>
                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Priority</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder='Priority'
                                            name="priority"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={action.priority}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Priority
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Medium">Medium</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="High">High</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Critical">Critical</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Recommendation/Comments</label>
                                        <input
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}    h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="comments"
                                            value={action.comments}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>OTC Go-Live Need?</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="otcneeded"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={action.otcneeded}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select OTC Go-Live Need?
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="No">No</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Yes">Yes</option>
                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>OTC Go-Live Critical?</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="otccritical"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={action.otccritical}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select OTC Go-Live Critical?
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="No">No</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Yes">Yes</option>
                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                        <label>Hypercare Critical</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="hypercarecritical"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={action.hypercarecritical}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Hypercare Critical
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="No">No</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Yes">Yes</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-center">
                                <Button type="submit" onClick={handelAddAction} variant="contained" sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100], }} >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddAction
