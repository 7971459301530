import { Link } from 'react-router-dom';
import { Box, Button, Grid, Paper, Typography, useTheme } from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { tokens } from "../../../theme";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Header from "../../../components/Header";
import FunctionChart from '../../../components/FunctionChart';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import StatusBox from "../../../components/StatusBox"
import { MultipleStopOutlined, PrivacyTipOutlined, TourOutlined, VpnKeyOffOutlined, DoneAllOutlined, RowingOutlined, BugReportOutlined, StreamOutlined, AssessmentOutlined } from '@mui/icons-material';
import { useAuth } from '../../../context/authContext';
import { useEffect, useState } from 'react';



const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 25,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 700 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const ProjectDetails = () => {
  const theme = useTheme();
  useEffect(() => {
    document.title = "iDeal - Project Details";
  }, [])
  const colors = tokens(theme.palette.mode);
  const { currentproject } = useAuth()
  const [isLoading, setisLoading] = useState(false)
  const [RiskData, setRiskData] = useState("")
  const [IssueData, setIssueData] = useState("")
  const [TaskData, setTaskData] = useState("")
  const [MilestoneData, setMilestoneData] = useState("")
  const getRiskData = async (userid) => {
    setisLoading(true)
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/risk';
    try {
      const response = await fetch(`${lambdaEndpoint}?userid=${userid}`, {
        method: 'GET',
        headers: {
          "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      //console.log('Response:', response);
      //console.log('Response Data:', data);
      setRiskData(data.Item)
    } catch (error) {
      console.error('Error:', error);
    }
    setisLoading(false)
  }
  const getissueData = async (userid) => {
    setisLoading(true)
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/issue';
    try {
      const response = await fetch(`${lambdaEndpoint}?userid=${userid}`, {
        method: 'GET',
        headers: {
          "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
        },
      });

      const data = await response.json();
      //console.log('Response:', response);
      //console.log('Response Data:', data);
      setIssueData(data.Item)
    } catch (error) {
      console.error('Error:', error);
    }
    setisLoading(false)
  }
  const gettaskData = async (userid) => {
    setisLoading(true)
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/task';
    try {
      const response = await fetch(`${lambdaEndpoint}?userid=${userid}`, {
        method: 'GET',
        headers: {
          "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
        },
      });

      const data = await response.json();
      //console.log('Response:', response);
      //console.log('Response Data:', data);
      setTaskData(data.Item)
    } catch (error) {
      console.error('Error:', error);
    }
    setisLoading(false)
  }
  const getmilestoneData = async (userid) => {
    setisLoading(true)
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/milestone';
    try {
      const response = await fetch(`${lambdaEndpoint}?userid=${userid}`, {
        method: 'GET',
        headers: {
          "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
        },
      });

      const data = await response.json();
      //console.log('Response:', response);
      //console.log('Response Data:', data);
      setMilestoneData(data.Item)
    } catch (error) {
      console.error('Error:', error);
    }
    setisLoading(false)
  }
  useEffect(() => {
    const userid = localStorage.getItem("user")
    getRiskData(userid)
    getissueData(userid);
    gettaskData(userid);
    getmilestoneData(userid);
  }, [])

  const openRisk = Array?.isArray(RiskData) ? RiskData?.filter((risk) => risk?.projectname === currentproject?.projectname && risk.riskstatus === "Monitor")?.length : []
  const openIssue = Array?.isArray(IssueData) ? IssueData?.filter((issue) => issue?.projectname === currentproject?.projectname && issue.status !== "Closed")?.length : []
  const today = new Date();
  const task = Array?.isArray(TaskData) ? TaskData?.filter((task) => task?.projectname === currentproject?.projectname && task?.finishdate instanceof Date && task?.finishdate.toDateString() === today.toDateString()) : [];
  const overDueTask = task?.length
  const dependencies = Array?.isArray(TaskData) ? TaskData?.filter((task) => task?.projectname === currentproject?.projectname && task?.interlockduedate instanceof Date && task?.interlockduedate.toDateString() === today.toDateString()) : [];
  const overDueDependencies = dependencies?.length
  const milestone = Array?.isArray(MilestoneData) ? MilestoneData?.filter((milestone) => milestone?.projectname === currentproject?.projectname && milestone?.finishdate instanceof Date && milestone?.finishdate.toDateString() === today.toDateString()) : [];
  const overDueMilestones = milestone?.length
  const combineData = [...TaskData, ...MilestoneData]
  const notinterlockedDependencies = Array?.isArray(combineData) ? combineData?.filter((combine) => combine?.projectname === currentproject?.projectname && combine?.dependencieslocked === "No").length : [];
  return (
    <Box m="20px" >
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={"10px"}>
        <Header title={`PROJECT DETAILS: ${currentproject?.projectname}`} />
        <Box >
          <Link to="/projects/active">
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              <KeyboardBackspaceIcon sx={{ mr: "10px" }} />
              <span className="2xl:block xl:block lg:block md:block sm:hidden hidden"> BACK </span>
            </Button>
          </Link>
        </Box>

      </Box>
      {/* Grid */}

      <Grid container spacing={2} marginTop={"20px"}>
        <Box className="w-[100%]  mx-[10px] my-[10px] 2xl:flex 2xl:flex-row xl:flex xl:flex-row lg:flex lg:flex-row md:flex md:flex-row  sm:flex sm:flex-col"  >
          <Paper className=" 2xl:w-[35%] xl:w-[35%] lg:w-[35%] md:w-[35%] sm:w-[100%]" style={{ backgroundColor: colors.primary[400] }} >
            {/* START TOP STATUS*/}
            <Box marginTop={"20px"} >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: "10px" }}>
                <Box sx={{ width: '100%' }}>
                  <Typography
                    variant="h5"
                    p="0px 0px 5px 30px"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    OVERALL PROGRESS
                  </Typography>
                  <Box p="5px 5px 5px 30px">
                    <BorderLinearProgress variant="determinate" value={
                      currentproject?.progress === "On-Track" ? "20" :
                        currentproject?.progress === "Active" ? "30" :
                          currentproject.progress === "InActive" ? "10" :
                            currentproject?.progress === "Completed" ? "100" :
                              "0"
                    } />
                  </Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    OVERALL STATUS
                  </Typography>

                  {/* TEMP OPEN*/}
                  <Box p="5px 15px 5px 30px">
                    <Typography variant="h4" sx={{ backgroundColor: "#22b922", color: ` ${theme.palette.mode === "light" ? "black" : "white"}`, padding: "2px 15px" }}>
                      {currentproject?.progress}
                    </Typography>
                  </Box>
                  {/* TEMP close*/}
                </Box>
              </Box>
              {/* END  TOP STATUS*/}

              {/* START STATTUS BOX GRID*/}
              <Box margin="20px">
                <Grid container spacing={0}>
                  <Grid item xs={4} >
                    <Box borderRight={1} borderBottom={1} textAlign="center" height="120px" sx={{ borderColor: colors.grey[500] }}>
                      <StatusBox
                        sx={{
                          fontSize: "14px"
                        }}
                        title="OPEN RISK"
                        icon={
                          <PrivacyTipOutlined
                            sx={{ color: colors.greenAccent[600], fontSize: "25px" }}
                          />
                        }
                        statnumber={openRisk > 0 ? openRisk : "0"}
                      />
                    </Box>
                    <Box borderRight={1} textAlign="center" height="120px" sx={{ borderColor: colors.grey[500] }}>
                      <StatusBox
                        sx={{
                          fontSize: "14px"
                        }}
                        title="DEPENDENCIES NOT INTERLOCKED"
                        icon={
                          <VpnKeyOffOutlined
                            sx={{ color: colors.greenAccent[600], fontSize: "25px" }}
                          />
                        }
                        notinterlockedDependencies
                        statnumber={notinterlockedDependencies > 0 ? notinterlockedDependencies : "0"}
                      />
                    </Box>

                  </Grid>
                  <Grid item xs={4} >
                    <Box borderRight={1} borderBottom={1} textAlign="center" height="120px" sx={{ borderColor: colors.grey[500] }} >
                      <StatusBox
                        sx={{
                          fontSize: "14px"
                        }}
                        title="OPEN ISSUES"
                        icon={
                          <BugReportOutlined
                            sx={{ color: colors.greenAccent[600], fontSize: "25px" }}
                          />
                        }
                        statnumber={openIssue > 0 ? openIssue : "0"}
                      />
                    </Box>
                    <Box borderRight={1} textAlign="center" height="120px" sx={{ borderColor: colors.grey[500] }}>
                      <StatusBox
                        sx={{
                          fontSize: "14px"
                        }}
                        title="OVERDUE DEPENDENCIES"
                        icon={
                          <MultipleStopOutlined
                            sx={{ color: colors.greenAccent[600], fontSize: "25px" }}
                          />
                        }
                        statnumber={overDueDependencies > 0 ? overDueDependencies : "0"}
                      />

                    </Box>

                  </Grid>
                  <Grid item xs={4}>
                    <Box borderBottom={1} textAlign="center" height="120px" sx={{ borderColor: colors.grey[500] }}>
                      <StatusBox
                        sx={{
                          fontSize: "14px"
                        }}
                        title="OVERDUE TASK"
                        icon={
                          <DoneAllOutlined
                            sx={{ color: colors.greenAccent[600], fontSize: "25px" }}
                          />
                        }
                        statnumber={overDueTask > 0 ? overDueTask : "0"}
                      />
                    </Box>
                    <Box textAlign="center" height="120px">
                      <StatusBox
                        sx={{
                          fontSize: "14px"
                        }}
                        title="OVERDUE MILESTONE"
                        icon={
                          <TourOutlined
                            sx={{ color: colors.greenAccent[600], fontSize: "25px" }}
                          />
                        }
                        statnumber={overDueMilestones > 0 ? overDueMilestones : "0"}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box width={"100%"} display={"flex"} padding={"5px"} flexDirection={"column"}>
                <Box className="flex 2xl:w-[100%] xl:w-[100%] lg:w-[90%] md:w-[65%]" >
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={"100%"}
                  >
                    <Box
                      width={"50%"}
                      height="150px"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      marginRight='10px'
                      sx={{
                        "&:hover": {
                          backgroundColor: "#2d374c",
                        },
                      }}
                    >
                      <Link to="/workplan" style={{
                        width: '100%',
                        display: 'flex',
                        height: '150px',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }} >
                        <Button>
                          <Box display="flex" flexDirection="column" alignItems="center" sx={{ color: colors.grey[100] }}>
                            <AlarmOnIcon sx={{ fontSize: "30px" }} />
                            <Box variant="h4" sx={{ m: "10px 0 0 0" }}>WORKPLAN</Box>
                          </Box>
                        </Button>
                      </Link>
                    </Box>
                    <Box
                      width={"50%"}
                      height="150px"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      marginRight='10px'
                      sx={{
                        "&:hover": {
                          backgroundColor: "#2d374c",
                        },
                      }}
                    >
                      <Link to="/task" style={{
                        width: '100%',
                        display: 'flex',
                        height: '150px',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }} >
                        <Button>
                          <Box display="flex" flexDirection="column" alignItems="center" sx={{ color: colors.grey[100] }}>
                            <AlarmOnIcon sx={{ fontSize: "30px" }} />
                            <Box variant="h4" sx={{ m: "10px 0 0 0" }}>TASK</Box>
                          </Box>
                        </Button>
                      </Link>
                    </Box>
                  </Grid>
                  <Grid
                    display='flex'
                    width='50%'
                    flexDirection='column' >
                    <Grid display={"flex"}>
                      <Box
                        height="72px"
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width='50%'
                        margin="0px 6px 0px 0px"
                        sx={{
                          "&:hover": {
                            backgroundColor: "#2d374c",
                          },
                        }}

                      >
                        <Link to={{
                          pathname: '/risk',
                          state: { currentproject: currentproject } // Pass your project data here
                        }} style={{
                          width: '100%',
                          display: 'flex',
                          height: '72px',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                          <Button>
                            <Box display="flex" alignItems="center" flexDirection='column' sx={{ color: colors.grey[100] }}>
                              <PrivacyTipOutlined />
                              <Box className="2xl:text-[16px] xl:text-[14px] lg:text-[14px] md:text-[9px] sm:text-[7px] text-[7px]" variant="h4" sx={{ padding: "5px" }}>RISK</Box>
                            </Box>
                          </Button>
                        </Link>
                      </Box>
                      <Box
                        height="72px"
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        width='50%'
                        margin="0px 0px 0px 0px"
                        sx={{
                          "&:hover": {
                            backgroundColor: "#2d374c",
                          },
                        }}
                      >
                        <Link to="/actions" style={{
                          width: '100%',
                          display: 'flex',
                          height: '72px',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                          <Button>
                            <Box display="flex" alignItems="center" flexDirection='column' sx={{ color: colors.grey[100] }}>
                              <RowingOutlined />
                              <Box className="2xl:text-[16px] xl:text-[14px] lg:text-[14px] md:text-[9px] sm:text-[7px] text-[7px]" variant="h4" sx={{ padding: "5px" }}>ACTION</Box>
                            </Box>
                          </Button>
                        </Link>
                      </Box>
                    </Grid >
                    <Grid display={"flex"} >
                      <Box
                        height="72px"
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        width='50%'
                        margin="5px 5px 0px 0px"
                        sx={{
                          "&:hover": {
                            backgroundColor: "#2d374c",
                          },
                        }}
                      >
                        <Link to="/issues" style={{
                          width: '100%',
                          display: 'flex',
                          height: '72px',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                          <Button>
                            <Box display="flex" alignItems="center" flexDirection='column' sx={{ color: colors.grey[100] }}>
                              <BugReportOutlined />
                              <Box className="2xl:text-[16px] xl:text-[14px] lg:text-[14px] md:text-[9px] sm:text-[7px] text-[7px]" variant="h4" sx={{ padding: "5px" }}>ISSUES</Box>
                            </Box>
                          </Button>
                        </Link>
                      </Box>
                      <Box
                        height="72px"
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        width='50%'
                        margin="5px 0px 0px 0px"
                        sx={{
                          "&:hover": {
                            backgroundColor: "#2d374c",
                          },
                        }}
                      >

                        <Link to="/decision" sx={{
                          color: colors.grey[100],
                          textDecoration: 'none',
                          '&:hover': {
                            textDecoration: 'underline'
                          },
                          width: '100%',
                          display: 'flex',
                          height: '150px',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                          <Button>
                            <Box display="flex" alignItems="center" flexDirection='column' sx={{ color: colors.grey[100] }}>
                              <StreamOutlined />
                              <Box className="2xl:text-[16px] xl:text-[14px] lg:text-[14px] md:text-[9px] sm:text-[7px] text-[7px]" variant="h4" sx={{ padding: "5px" }}>DECISION</Box>
                            </Box>
                          </Button>
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box width={"100%"} display={"flex"} marginTop={"10px"}>
                  <Grid
                    width="100%"
                  >
                    <Box
                      height="150px"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      marginRight={"10px"}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#2d374c",
                        },
                      }}
                    >
                      <Link to="/dependencies/tracker" style={{
                        width: '100%',
                        display: 'flex',
                        height: '150px',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        <Button>
                          <Box display="flex" flexDirection="column" alignItems="center" sx={{ color: colors.grey[100] }}>
                            <MultipleStopOutlined sx={{ fontSize: "30px" }} />
                            <Typography variant="h4" sx={{ m: "10px 0 0 0", fontSize: "14px" }} >DEPENDENCY TRACKER</Typography>
                          </Box>
                        </Button>
                      </Link>
                    </Box>
                  </Grid>
                  <Grid
                    width="100%"
                  >
                    <Box
                      height="150px"
                      backgroundColor={colors.primary[400]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      sx={{
                        "&:hover": {
                          backgroundColor: "#2d374c",
                        },
                      }}

                    >
                      <Link to="/dependencies" style={{
                        width: '100%',
                        display: 'flex',
                        height: '150px',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        <Button>
                          <Box display="flex" flexDirection="column" alignItems="center" sx={{ color: colors.grey[100] }}>
                            <AssessmentOutlined sx={{ fontSize: "30px" }} />
                            <Typography variant="h4" sx={{ m: "10px 0 0 0", fontSize: "14px" }}>REPORT</Typography>
                          </Box>
                        </Button>
                      </Link>
                    </Box>
                  </Grid>
                </Box>
              </Box>
              {/* END STATTUS BOX GRID*/}
            </Box>
          </Paper>
          {/* Function Chart  Grid*/}
          <Grid  className="h-[700px] 2xl:w-[65%] xl:w-[65%] lg:w-[65%] md:w-[65%] sm:w-[100%] ml-[5px] max-sm:mt-3">
            <Paper style={{ height:"100%", paddingBottom: 30, backgroundColor: colors.primary[400] }} >
              <FunctionChart  />
            </Paper>
          </Grid>
        </Box>
      </Grid>
    </Box>

  );
};

export default ProjectDetails;
