import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { RuleOutlined } from "@mui/icons-material";
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddAction from "../../Modals/actionmodal/addAction";
import EditAction from "../../Modals/actionmodal/editAction";
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from "react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import RowingOutlined from '@mui/icons-material/RowingOutlined';
import {
  DoneAllOutlined,
  AssignmentTurnedInOutlined,
  StreamOutlined,
  PrivacyTipOutlined,
  MultipleStopOutlined,
  BugReportOutlined,
  AssignmentOutlined,
  TourOutlined,
} from "@mui/icons-material";

const Actions = ({ projectData }) => {
  const theme = useTheme();
  const { currentproject } = useAuth();
  const colors = tokens(theme.palette.mode);
  const [AddModalToggle, setAddModalToggle] = useState(false);
  const [EditModalToggle, setEditModalToggle] = useState(false);
  const initialState = currentproject?.projectname ? currentproject?.projectname : ""
  const [selectedProject, setselectedProject] = useState(initialState)
const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path;
  };
  useEffect(() => {
    document.title = "iDeal - Actions";
  }, [])
  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
    },
    {
      field: "projectname",
      headerName: "Project Name",
      width: 200,
    },
    {
      field: "title",
      headerName: "Decision Title",
      width: 200,
    },
    {
      field: "primaryfunctions",
      headerName: "Primary Function",
      width: 200,
    },
    {
      field: "secondaryfunctions",
      headerName: "Secondary Function",
      width: 200,
    },
    {
      field: "location",
      headerName: "Location",
      width: 100,
    },
    {
      field: "raid",
      headerName: "RAID",
      width: 100,
    },
    {
      field: "topic",
      headerName: "Topic",
      width: 300,
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
    },
    {
      field: "source",
      headerName: "Source",
      width: 200,
    },
    {
      field: "raisedby",
      headerName: "Raised By",
      width: 200,
    },
    {
      field: "owner",
      headerName: "Owner",
      width: 200,
    },
    {
      field: "tmopoc",
      headerName: "TMO POC",
      width: 200,
    },
    {
      field: "duedate",
      headerName: "Due Date",
      flex: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 200,
    },
    {
      field: "comments",
      headerName: "Recommendation/Comments",
      width: 200,
    },
    {
      field: "otcneeded",
      headerName: "OTC Go-live Need?",
      width: 200,
    },
    {
      field: "otccritical",
      headerName: "OTC Go-live Critical?",
      width: 200,
    },
    {
      field: "hypercarecritical",
      headerName: "Hypercare Critical",
      width: 200,
    },
    {
      field: 'editaction',
      headerName: 'Edit Action',
      width: 50,
      renderCell: (params) => {
        return (
          <Button
            sx={{
              fontSize: "7px",
              fontWeight: "bold",
              padding: "5px 5px",
            }}
            onClick={() => handleEditAction(params.row)}
          >
            < EditNoteIcon sx={{ mr: "10px", color: `${theme.palette.mode === "light" ? "black" : "white"}` }} />
          </Button>
        );
      },
    },
    {
      field: 'deleteaction',
      headerName: 'Delete Action',
      width: 50,
      renderCell: (params) => {
        return (
          <Button
            sx={{
              fontSize: "7px",
              fontWeight: "bold",
              padding: "5px 5px",
            }}
            onClick={() => handleDeleteAction(params.row.id)}
          >
            < DeleteForeverIcon sx={{ mr: "10px", color: `${theme.palette.mode === "light" ? "black" : "white"}` }} />
          </Button>
        );
      },
    },
  ];

  const [actionData, setactionData] = useState("")
  const userid = localStorage.getItem("user")
  const [isLoading, setisLoading] = useState(false)
  const uid = function () {
    return Date.now() + Math.floor(Math.random());
  }
  const [action, setaction] = useState({
    userid: userid,
    id: uid(),
    date: "",
    projectname: "",
    title: "",
    primaryfunctions: "",
    secondaryfunctions: "",
    location: "",
    raid: "",
    topic: "",
    description: "",
    source: "",
    raisedby: "",
    owner: "",
    tmopoc: "",
    duedate: "",
    status: "",
    priority: "",
    comments: "",
    otcneeded: "",
    otccritical: "",
    hypercarecritical: ""
  })
  const getactionData = async (userid) => {
    setisLoading(true)
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/action';
    try {
      const response = await fetch(`${lambdaEndpoint}?userid=${userid}`, {
        method: 'GET',
        headers: {
          "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
        },
      });

      const data = await response.json();
      //console.log('Response:', response);
       //console.log('Response Data:', data);
      setactionData(data.Item)
    } catch (error) {
      console.error('Error:', error);
    }
    setisLoading(false)
  }
  useEffect(() => {
    const userid = localStorage.getItem("user");
    getactionData(userid);
  }, [action, setaction]);
  //console.log(actionData)
  const handleDeleteAction = async (rowId) => {
    //console.log(`Delete Action clicked for row with ID: ${rowId}`);
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/action';
    const body = JSON.stringify({ "id": rowId });
    try {
      const response = await fetch(`${lambdaEndpoint}`, {
        method: 'DELETE',
        headers: {
          "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
        },
        body: body
      });
      //console.log('Delete operation successful', response);
      const userid = localStorage.getItem("user");
      getactionData(userid);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const [EditId, setEditId] = useState("")
  const handleEditAction = (rowId) => {
    //console.log(`Edit Action clicked for row with ID: ${rowId.id}`);
    setEditId(rowId)
    //console.log(rowId)
    setEditModalToggle(!EditModalToggle)
  }
  const handelGoBack = () => {
    window.history.back()
  }
  const projectAction = Array?.isArray(actionData) ? actionData?.filter((action) => action.projectname === selectedProject) : []
  return (
    <>
      <Box m="10px">
      <Box >
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
              <li className="inline-flex items-center">
              <PrivacyTipOutlined className={`mr-2 text-xs  ${isActive('/risk') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'}`} />
                <Link
                  style={{
                    fontWeight: '700',
                    fontFamily: 'sans-serif'
                  }}
                  to="/risk"
                  className={`inline-flex items-center text-sm font-medium ${isActive('/risk') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600`}
                >
                  Risks
                </Link>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="w-3 h-3 text-gray-400 mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <RowingOutlined className={`mr-2 text-xs  ${isActive('/actions') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'}`} />
                  <Link
                    style={{
                      fontWeight: '700',
                      fontFamily: 'sans-serif'
                    }}
                    to="/actions"
                    className={`inline-flex items-center text-sm font-medium ${isActive('/actions') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600 `}
                  >
                    Actions
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="w-3 h-3 text-gray-400 mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <BugReportOutlined className={`mr-2 text-xs  ${isActive('/issues') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'}`} />
                  <Link
                    style={{
                      fontWeight: '700',
                      fontFamily: 'sans-serif'
                    }}
                    to="/issues"
                    className={`inline-flex items-center text-sm font-medium ${isActive('/issues') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600 `}
                  >
                    Issues
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="w-3 h-3 text-gray-400 mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <StreamOutlined className={`mr-2 text-xs  ${isActive('/decision') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'}`} />
                  <Link
                    style={{
                      fontWeight: '700',
                      fontFamily: 'sans-serif'
                    }}
                    to="/decision"
                    className={`inline-flex items-center text-sm font-medium ${isActive('/decision') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600 `}
                  >
                    Decisions
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={"20px"}>
          <Header title="Actions" subtitle="List of actions"
          />
          <Box display="flex" alignItems="center">
          <Box sx={{ marginRight: "5px" }}>
              <div className="mb-4 mr-2 w-[100%] cursor-pointer" >
                <label style={{
                  fontFamily: 'sans-serif',
                  fontWeight: '700'
                }}>Select Project</label>
                <select
                  style={{
                    backgroundColor: theme.palette.mode === 'light' ? '#0393fb' : '#2068bf' ,
                    borderRadius: '4px',
                    fontFamily: "sans-serif",
                    fontWeight:"700"
                  }}
                  placeholder=''
                  name="project"
                  className={`block cursor-pointer w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[45px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                  value={selectedProject}
                  onChange={(event) => {
                    const selectedProject = event.target.value;
                    setselectedProject(selectedProject);
                  }}
                >
                  <option
                    className={`cursor-pointer ${theme.palette.mode === 'light'
                      ? 'bg-black text-white'
                      : 'bg-gray-500 text-black'
                      }`}
                    value=""
                    disabled
                  >
                    <AssignmentOutlined sx={{ mr: "2px", color: `${theme.palette.mode === "light" ? "black" : "black"}` }} />
                     Select Project
                  </option>
                  {projectData?.length > 0 ? (
                    projectData.map((project, index) => {
                      return (

                        <option
                          key={index}
                          className={`cursor-pointer ${theme.palette.mode === 'light'
                            ? 'bg-black text-white'
                            : 'bg-gray-500 text-black'
                            }`}
                          value={project.projectname}
                        >
                          {project.projectname}
                        </option>
                      )

                    })
                  ) : (
                    <option value="" disabled>
                      No Projects are available
                    </option>
                  )}
                </select>
              </div>
            </Box>
            <Box>
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  marginRight: "5px",
                  lineHeight: "1.75rem"

                }}
                onClick={() => setAddModalToggle(!AddModalToggle)}
              >
                <RuleOutlined sx={{ mr: "10px" }} />
                <span  className="max-md:text-[10px] text-[14px] 2xl:block xl:block lg:block md:block sm:hidden hidden">Create Action</span>
              </Button>
            </Box>
            <Box>
              <button
                className="hover:bg-transparent"
                style={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  display:"flex",
                  alignItems:"center",
                  padding: "9px 20px",
                  zIndex: "-1",
                  borderRadius: "4px",
                  lineHeight: "1.75rem"
                }}
                onClick={handelGoBack}
              >
                <KeyboardBackspaceIcon sx={{ mr: "10px" }} />
                <span  className="2xl:block xl:block lg:block md:block sm:hidden hidden"> BACK </span>
              </button>
            </Box>
          </Box>
        </Box>
        {projectAction?.length > 0 ? <Box>
          <Box
            m="10px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-main": {
                border: ` 1px solid ${theme.palette.mode === "light" ? "black" : "white"}`,
              },
              "& .MuiDataGrid-cell": {
                borderBottom: ` 1px solid ${theme.palette.mode === "light" ? "black" : "white"}`,
              },
              "& .name-column--cell": {
                color: `${theme.palette.mode === "light" ? "black" : "white"}`,
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
              '& .Confirmed': {
                backgroundColor: '#041541',
                color: `${theme.palette.mode === "light" ? "white" : "white"}`,
              },
              '& .Open': {
                backgroundColor: '#26aad5',
                color: `${theme.palette.mode === "light" ? "black" : "white"}`,
              },
              '& .High': {
                backgroundColor: 'red',
                color: `${theme.palette.mode === "light" ? "black" : "black"}`,
              },
              '& .Medium': {
                backgroundColor: 'orange',
                color: `${theme.palette.mode === "light" ? "black" : "black"}`,
              },
              '& .Low': {
                backgroundColor: 'yellow',
                color: `${theme.palette.mode === "light" ? "black" : "black"}`,
              },
            }}
          >
            <DataGrid
              rowsPerPageOptions={[5, 10, 25, 100]}
              count={projectAction.length}
              rows={projectAction}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              getCellClassName={(params) => {
                if (params.value === 'Confirmed') {
                  return 'Confirmed';
                }
                else if (params.value === 'Open') {
                  return 'Open';
                }
                else if (params.value === 'High') {
                  return 'High';
                }
                else if (params.value === 'Medium') {
                  return 'Medium';
                }
                else if (params.value === 'Low') {
                  return 'Low';
                }
              }}
            />
          </Box>
        </Box>
          :
          (isLoading ? <div className="text-center m-12">
            <div role="status">
              <svg aria-hidden="true" className="inline w-12 h-12 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div> :
            <Box sx={{}}>
              <Box sx={{
                fontSize: "2rem",
                textAlign: 'center',
                marginTop: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <CancelIcon sx={{ mr: "10px", height: '2rem', width: '2rem' }} /> <span style={{}}> Data Are Not Available For Actions.</span>
              </Box>
            </Box>
          )
        }
      </Box>
      {AddModalToggle && <AddAction action={action} setaction={setaction} projectData={projectData} AddModalToggle={AddModalToggle} setAddModalToggle={setAddModalToggle} />}
      {EditModalToggle && <EditAction EditId={EditId} setEditId={setEditId} projectData={projectData} getactionData={getactionData} EditModalToggle={EditModalToggle} setEditModalToggle={setEditModalToggle} />}
    </>
  );
};

export default Actions;
