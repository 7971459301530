import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockFunctionData as data } from "../data/mockData";
import { useAuth } from '../context/authContext';
const FunctionChart = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setisLoading] = useState(false)
  const [FunctionChartData, setFunctionChartData] = useState("")
  const [taskData, settaskData] = useState("")
  const { currentproject } = useAuth()
  const GetChartFunctionData = async () => {
    const userid = localStorage.getItem("user")
    setisLoading(true)
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/functionchart';
    try {
      const response = await fetch(`${lambdaEndpoint}?userid=${userid}`, {
        method: 'GET',
        headers: {
          "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
        },
      });

      const data = await response.json();
      // console.log('Response:', response);
      // console.log('Response Data:', data);
      setFunctionChartData(data.Data)
    } catch (error) {
      console.error('Error:', error);
    }
    setisLoading(false)
  }
  const gettaskData = async (userid) => {
    setisLoading(true)
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/task';
    try {
      const response = await fetch(`${lambdaEndpoint}?userid=${userid}`, {
        method: 'GET',
        headers: {
          "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
        },
      });

      const data = await response.json();
      // console.log('Response:', response);
      // console.log('Response Data:', data);
      settaskData(data.Item)
    } catch (error) {
      console.error('Error:', error);
    }
    setisLoading(false)
  }
  useEffect(() => {
    GetChartFunctionData()
    const userid = localStorage.getItem("user")
    gettaskData(userid)
  }, [])

  const chartData = Array.isArray(taskData)
  ? taskData
      .filter((chart) => chart.projectname === currentproject?.projectname)
      .map((chartData) => {
        const complete = chartData.complete ? parseInt(chartData.complete) : 0;
        const onTrack = chartData.status === "Complete" ? 100 : 50;
        const inProgress = 200 - complete - onTrack;

        return {
          "Complete": complete,
          "CompleteColor": "hsl(219, 70%, 50%)",
          "On-Track": onTrack,
          "On-TrackColor": "hsl(252, 70%, 50%)",
          "In Progress": inProgress >= 0 ? inProgress : 0,
          "In ProgressColor": "hsl(46, 70%, 50%)",
          "functions": chartData.functions,
        };
      })
  : [];
  // console.log(chartData)

  return (
    <ResponsiveBar
      style={{ marginLeft: "25px", marginBottom: "15px" }}
      data={chartData}
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
      }}
      keys={[
        'Complete',
        'On-Track',
        'In Progress',
      ]}
      indexBy="functions"
      margin={{ top: 70, right: 60, bottom: 40, left: 130 }}
      padding={0.5}
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'nivo' }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#38bcb2',
          size: 4,
          padding: 1,
          stagger: true
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10
        }
      ]}
      fill={[
        {
          match: {
            id: 'fries'
          },
          id: 'dots'
        },
        {
          match: {
            id: 'sandwich'
          },
          id: 'lines'
        }
      ]}
      borderColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            3
          ]
        ]
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 30
      }}
      axisLeft={{
        tickSize: 2,
        tickPadding: 2,
        tickRotation: 0,
        legend: 'Function',
        legendPosition: 'middle',
        legendOffset: -100
      }}
      enableLabel={true}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            3
          ]
        ]
      }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateX: -10,
          translateY: -20,
          itemsSpacing: 2,
          itemWidth: 82,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in functions: " + e.indexValue }}
    />
  );
};

export default FunctionChart;
