import React, { useEffect } from 'react'
import { Box, Button, Typography, useTheme, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { tokens } from "../theme"
import Header from "../components/Header";
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../context/authContext';
import { useState } from 'react';
import AWS from 'aws-sdk';


AWS.config.update({
    region: 'eu-north-1',
    accessKeyId: 'AKIA6AWA27EBIZT3FY6W',
    secretAccessKey: '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',
});
const s3 = new AWS.S3();
const ProjectInfo = ({ OpenProjectInfo, setOpenProjectInfo }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { currentproject } = useAuth()
    const [CurrentUserImage, setCurrentUserImage] = useState("")
    const [isLoading, setisLoading] = useState(false)
    const GetUserImage = async () => {
        setisLoading(true);
        const username = localStorage.getItem("username")
        const params = {
            Bucket: 'idealuserimage',
            Key: username,
        };
        s3.getObject(params, (err, data) => {
            if (err) {
                console.error('Error retrieving object:', err);
            } else {
                // The object data is in the "Body" property of the response
                const objectData = data.Body.toString('utf-8'); // Assuming it's a text file
                // console.log('Object data:', objectData);
                setCurrentUserImage(objectData)
            }
        });
        setisLoading(false)
    }
    useEffect(() => {
        GetUserImage()
    }, [])
    return (
        <div
            data-te-modal-init
            className={` fixed left-0 top-0 z-[1055] ${OpenProjectInfo ? "block" : "hidden"} h-full w-full overflow-y-auto overflow-x-hidden outline-none`}
            id="exampleModalLg"
            tabIndex="-1"
            aria-labelledby="exampleModalLgLabel"
            aria-modal="true"
            role="dialog">
            <div
                className={`${theme.palette.mode === 'light' ? 'bg-[#eef0f2]' : 'bg-[#141b2d]'} 2xl:mt-[5px] 2xl:ml-[235px] 
                xl:mt-[5px] xl:ml-[150px] lg:mt-[5px] lg:ml-[100px] md:mt-[70px] md:ml-[70px] max-sm:mt-[70px] max-sm:ml-[50px] pointer-events-none  relative w-[80%] duration-300 ease-in-out`}
                style={{
                    boxShadow: `0px 0px 3px 0px  ${theme.palette.mode === "light" ? "black " : "white"}`,
                }}
                data-te-modal-dialog-ref>
                <div
                    className="pointer-events-auto relative flex w-full flex-col rounded-md border-none  bg-clip-padding text-current shadow-lg outline-none ">
                    <div
                        className="flex flex-shrink-0 items-center justify-between rounded-t-md  border-opacity-100 p-4 dark:border-opacity-50">
                        <Header title="Project Info" sx={{ color: `${colors === 'light' ? 'black' : 'white'}` }} />
                        <Box sx={{
                            backgroundColor: '#2068bf',
                            color: '#e0e0e0',
                            display: 'flex',
                            width: '40px',
                            borderRadius: '50%',
                            padding: '10px 10px',
                            alignContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                            onClick={() => setOpenProjectInfo(!OpenProjectInfo)}
                        >
                            < CloseIcon sx={{ mr: "10px" }} />
                        </Box>
                    </div>
                    <div className="relative p-4 overflow-y-auto max-sm:h-[75vh]">
                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                            <Box style={{ width: '100%' }} align='center'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Avatar
                                        alt="User Img"
                                        src={CurrentUserImage}
                                        sx={{ width: 90, height: 90 }} />
                                </Box>
                                <div >
                                    <Typography varients="h5" style={{ fontSize: 17 }}>{currentproject.projectowner}</Typography>
                                    <Typography varients="h5" style={{ fontSize: 17 }}>Project Leader </Typography>
                                </div>
                            </Box>
                            <TableContainer >
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><Typography variant='h5'>Deal Name :</Typography></TableCell>
                                            <TableCell ><Typography variant='h5'>{currentproject.projectname}</Typography></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><Typography variant='h5'>Deal Type :</Typography></TableCell>
                                            <TableCell><Typography variant='h5'>{currentproject.dealtype}</Typography></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><Typography variant='h5'>Deal Sign Date :</Typography></TableCell>
                                            <TableCell><Typography variant='h5'>{currentproject.dealsigndate}</Typography></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><Typography variant='h5'>Deal Close Date :</Typography></TableCell>
                                            <TableCell><Typography variant='h5'>{currentproject.dealclosedate}</Typography></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><Typography variant='h5'>Deal Value :</Typography></TableCell>
                                            <TableCell><Typography variant='h5'>{currentproject.dealvalue}</Typography></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><Typography variant='h5'>Personnel: </Typography></TableCell>
                                            <TableCell><Typography variant='h5'>{currentproject.personnel}</Typography></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><Typography variant='h5'>No of Markets : </Typography></TableCell>
                                            <TableCell><Typography variant='h5'>{currentproject.market}</Typography></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><Typography variant='h5'>Deal Description : </Typography></TableCell>
                                            <TableCell colSpan={2}>
                                                <Typography variant='h5'>
                                                    {currentproject.personneldealdescription}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectInfo