import React, { useState, useEffect } from 'react'
import { Box, Button, useTheme } from "@mui/material";
import { Amplify } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../components/Header';
import { tokens } from "../theme"
import Toaster from "../Toastify/Toaster"
import { toast } from 'react-toastify';
import AWS from 'aws-sdk';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);
const GetUserModal = ({ AllUserModal, setAllUserModal }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    AWS.config.update({
        region: 'eu-north-1',
        accessKeyId: 'AKIA6AWA27EBIZT3FY6W',
        secretAccessKey: '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',
    });
    Amplify.configure({
        Auth: {
            region: 'eu-north-1',
            userPoolId: 'eu-north-1_NLvW8NnoQ',
            userPoolWebClientId: '3559i49ok8ro4iqei4im5s5v8u',
        },
    });


    const fetchCognitoUsers = async () => {
        try {
            const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

            const userPoolId = 'eu-north-1_NLvW8NnoQ'; // Replace with your User Pool ID

            const params = {
                UserPoolId: userPoolId,
            };

            const response = await cognitoIdentityServiceProvider.listUsers(params).promise();

            // Set the list of users in your state
            setUsers(response.Users);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching Cognito users:', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchCognitoUsers();
    }, []);
    // console.log(users)

    const columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 0.5
        },
        {
            field: "Username",
            headerName: "Username",
            flex: 0.5
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email",
        },
        {
            field: "profile",
            headerName: "Profile",
            flex: 1,
        },
        {
            field: "UserStatus",
            headerName: "User Status",
            flex: 1,
        },
        {
            field: 'confirmuser',
            headerName: 'Confirm User',
            flex: 1,
            renderCell: (params) => {
                if (params.row.UserStatus === "CONFIRMED") {
                    return (
                        null
                    )
                }
                else{
                    return (
                        <Button
                            sx={{
                                fontSize: "7px",
                                fontWeight: "bold",
                                padding: "5px 5px",
                                backgroundColor: "green"
                            }}
                            onClick={() => confirmUserStatus(params.row.Username)}
                        >
                            < CheckIcon sx={{ mr: "10px", color: `${theme.palette.mode === "light" ? "black" : "white"}` }} />
                        </Button>
                    );
                }

            },
        },
        {
            field: 'deleteuser',
            headerName: 'Delete User',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Button
                        sx={{
                            fontSize: "7px",
                            fontWeight: "bold",
                            padding: "5px 5px",
                        }}
                        onClick={() => handleDeleteUser(params.row.Username)}
                    >
                        < DeleteForeverIcon sx={{ mr: "10px", color: `${theme.palette.mode === "light" ? "black" : "white"}` }} />
                    </Button>
                );
            },
        },
    ];

    const handleDeleteUser = async (username) => {
        // console.log(username)
        const cognito = new AWS.CognitoIdentityServiceProvider();
        const params = {
            UserPoolId: 'eu-north-1_NLvW8NnoQ', // Replace with your User Pool ID
            Username: username, // Replace with the username you want to delete
        };

        cognito.adminDeleteUser(params, (err, data) => {
            if (err) {
                console.error('Error deleting user:', err);
            } else {
                // console.log('User deleted successfully:', data);
                fetchCognitoUsers();
            }
        });
    }
    const confirmUserStatus = async (username) => {
        // console.log('Confirmed:', username);
        const requestBody = {
            UserPoolId: "eu-north-1_NLvW8NnoQ",
            Username: username,
          };
        const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/confirmuser';
        try {
            const response = await fetch(`${lambdaEndpoint}`, {
              method: 'POST',
              headers: {
                'x-api-key': 'WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty',
              },
              body: JSON.stringify(requestBody), // Convert the data to JSON format
            });
        
            const data = await response.json();
            fetchCognitoUsers()
            // console.log('Response:', response);
            // console.log('Response Data:', data);
          } catch (error) {
            console.error('Error:', error);
          }
    }
    return (
        <div
            data-te-modal-init
            className={` fixed left-0 top-0 z-[1055] ${AllUserModal ? "block" : "hidden"} h-full w-full overflow-y-auto overflow-x-hidden outline-none`}
            id="exampleModalLg"
            tabIndex="-1"
            aria-labelledby="exampleModalLgLabel"
            aria-modal="true"
            role="dialog">
            <Toaster />
            <div
                style={{
                    border: "20px",
                    backgroundColor: `${theme.palette.mode === 'light' ? "#eef0f2" : '#141b2d'}`,
                    boxShadow: `0px 0px 3px 0px  ${theme.palette.mode === "light" ? "black " : "white"}`,
                    marginTop: "100px",
                    marginRight: "250px"
                }}
                data-te-modal-dialog-ref
                className={`pointer-events-none   relative w-auto translate-y-[-50px]   transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px] min-[576px]:max-w-[500px] min-[992px]:max-w-[800px]`}>
                <div
                    className="pointer-events-auto relative flex w-full flex-col rounded-md border-none  bg-clip-padding text-current shadow-lg outline-none ">
                    <div
                        className="flex flex-shrink-0 items-center justify-between rounded-t-md  border-opacity-100 p-4 dark:border-opacity-50">
                        <Header title="All Users" subtitle={"List of Users"} sx={{ color: `${colors === 'light' ? 'black' : 'white'}` }} />
                        <Box sx={{
                            backgroundColor: '#2068bf',
                            color: '#e0e0e0',
                            display: 'flex',
                            width: '40px',
                            borderRadius: '50%',
                            padding: '10px 10px',
                            alignContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                            onClick={() => setAllUserModal(!AllUserModal)}
                        >
                            < CloseIcon sx={{ mr: "10px" }} />
                        </Box>
                    </div>
                    <div style={{ height: '400px' }}>
                        <Box
                            m="10px 0 0 0"
                            height="400px"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                },
                                "& .MuiDataGrid-main": {
                                    border: ` 1px solid ${theme.palette.mode === "light" ? "black" : "white"}`,
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .name-column--cell": {
                                    color: `${theme.palette.mode === "light" ? "black" : "white"}`,
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: colors.blueAccent[700],
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: colors.primary[400],
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    borderTop: "none",
                                    backgroundColor: "",
                                },
                                "& .MuiCheckbox-root": {
                                    color: `${colors.greenAccent[200]} !important`,
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${colors.grey[100]} !important`,
                                },

                            }}
                        >
                            <DataGrid
                                getRowId={(row) => row.id}
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                count={users.length}
                                rows={users.map((row, index) => ({
                                    id: index,
                                    Username: row.Username,
                                    name: row.Attributes[3]?.Value,
                                    email: row.Attributes[4]?.Value,
                                    profile: row.Attributes[2]?.Value,
                                    UserStatus: row.UserStatus
                                }))}
                                columns={columns}
                                components={{ Toolbar: GridToolbar }}

                            />
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetUserModal