import React, { useEffect, useState } from 'react'
import { Box, Button } from "@mui/material";
import { tokens } from "../../theme"
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const AddMilestone = ({ milestone, setmilestone, milestoneData, getmilestoneData, AddMilestoneModalToggle, setAddMilestoneModalToggle, projectData }) => {
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/milestone';
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // const generateid = (title, milestoneData) => {
    //     // Extract the first three words from the title
    //     const words = title.split(' ').slice(0, 3);

    //     // Combine the words with a count
    //     let id = `${words.join('')}${milestoneData.length + 1}`;

    //     // Check if the generated ID already exists in milestoneData
    //     while (milestoneData.some((item) => item.id === id)) {
    //       id = `${words.join(' ')} ${milestoneData.length + 1}`;
    //     }

    //     return id;
    //   };
    //   useEffect(() => {
    //     const newID = generateid(milestone.title, milestoneData); // Pass milestoneData if needed
    //     setmilestone((prevState) => ({
    //       ...prevState,
    //       workplanid: newID,
    //     }));
    //   }, [milestone.title])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setmilestone({
            ...milestone,
            [name]: value,
        });
    };


    const uid = function () {
        return Date.now() + Math.floor(Math.random());
    }
    const [durationInDays, setDurationInDays] = useState(0);
    useEffect(() => {
        const date1 = new Date(milestone.startdate);
        const date2 = new Date(milestone.finishdate);

        const durationInMilliseconds = Math.abs(date2 - date1);
        const calculatedDurationInDays = durationInMilliseconds / (1000 * 60 * 60 * 24);
        const finalDurationInDays = isNaN(calculatedDurationInDays) ? 0 : calculatedDurationInDays;

        setDurationInDays(finalDurationInDays);

        // console.log(`The duration between the two dates is ${calculatedDurationInDays} days.`);
    }, [milestone.startdate, milestone.finishdate]);
    const userid = localStorage.getItem("user")
    useEffect(() => {
        setmilestone((prevMilestone) => ({
            ...prevMilestone,
            id: uid(),
            duration: `${durationInDays} Days`,
        }));
    }, [setmilestone, durationInDays]);
    const handelAddMilestone = async (e) => {
        e.preventDefault();
        // console.log(milestone)
        try {
            const response = await fetch(lambdaEndpoint, {
                method: 'POST',
                headers: {
                    "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
                },
                body: JSON.stringify(milestone),
            });
            const data = await response.json();
            // console.log('Response:', response);
            // console.log('Response Data:', data);
        } catch (error) {
            console.error('Error:', error);
        }
        setmilestone({
            userid: userid,
            id: uid(),
            parentid: null,
            projectname: "",
            title: "",
            functions: "",
            subfunctions: "",
            crossfunctional: "",
            phase: "",
            description: "",
            startdate: "",
            finishdate: "",
            duration: "",
            status: "",
            complete: "",
            owner: "",
            dependencies: "",
            dependenciesfunction: "",
            dependencieslocked: "",
            interlockduedate: "",
            dependencydescritption: "",
            dependencystatus: "",
            comments: "",
        })
        setAddMilestoneModalToggle(!AddMilestoneModalToggle)
    }


    return (
        <div
            data-te-modal-init
            className={` fixed left-0 top-0 z-[1055] ${AddMilestoneModalToggle ? "block" : "hidden"} h-full w-full overflow-y-auto overflow-x-hidden outline-none`}
            id="exampleModalLg"
            tabIndex="-1"
            aria-labelledby="exampleModalLgLabel"
            aria-modal="true"
            role="dialog">
            <div
                className={`${theme.palette.mode === 'light' ? 'bg-[#eef0f2]' : 'bg-[#141b2d]'} 2xl:mt-[5px] 2xl:ml-[235px] 
                xl:mt-[5px] xl:ml-[150px] lg:mt-[5px] lg:ml-[100px] md:mt-[70px] md:ml-[70px] max-sm:mt-[50px] max-sm:ml-[50px] pointer-events-none  relative w-[80%] duration-300 ease-in-out`}
                style={{
                    boxShadow: `0px 0px 3px 0px  ${theme.palette.mode === "light" ? "black " : "white"}`,
                }}
                data-te-modal-dialog-ref>
                <div
                    className="pointer-events-auto relative flex w-full flex-col rounded-md border-none  bg-clip-padding text-current shadow-lg outline-none ">
                    <div
                        className="flex flex-shrink-0 items-center justify-between rounded-t-md  border-opacity-100 p-4 dark:border-opacity-50">
                        <Header title="Add Milestone" sx={{ color: `${colors === 'light' ? 'black' : 'white'}` }} />
                        <Box sx={{
                            backgroundColor: '#2068bf',
                            color: '#e0e0e0',
                            display: 'flex',
                            width: '40px',
                            borderRadius: '50%',
                            padding: '10px 10px',
                            alignContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                            onClick={() => setAddMilestoneModalToggle(!AddMilestoneModalToggle)}
                        >
                            < CloseIcon sx={{ mr: "10px" }} />
                        </Box>
                    </div>
                    <div className="relative p-4">
                        <form>
                        <div className='flex flex-col h-[75vh]  max-sm:h-[70vh] overflow-auto'>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Select Project</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="parentid"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={milestone.projectname}
                                            onChange={(event) => {
                                                const selectedProject = event.target.value;
                                                // Update the task state with the selected milestone's ID
                                                setmilestone({
                                                    ...milestone,
                                                    projectname: selectedProject,
                                                });
                                            }}
                                        >
                                            <option
                                                className={`${theme.palette.mode === 'light'
                                                    ? 'bg-black text-white'
                                                    : 'bg-gray-500 text-black'
                                                    }`}
                                                value=""
                                                disabled
                                            >
                                                Select Project
                                            </option>
                                            {projectData?.length > 0 ? (
                                                projectData.map((project, index) => {
                                                    return (

                                                        <option
                                                            key={index}
                                                            className={`${theme.palette.mode === 'light'
                                                                ? 'bg-black text-white'
                                                                : 'bg-gray-500 text-black'
                                                                }`}
                                                            value={project.projectname}
                                                        >
                                                            {project.projectname}
                                                        </option>
                                                    )

                                                })
                                            ) : (
                                                <option value="" disabled>
                                                    No Projects are available
                                                </option>
                                            )}


                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Title</label>
                                        <input
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}    h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="title"
                                            value={milestone.title}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Function</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="functions"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={milestone.functions}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Function
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Communications" >
                                                Communications
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Compliance" >
                                                Compliance
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Finance" >
                                                Finance
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="GSB-Customer Service" >
                                                GSB-Customer Service
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="GSB-Invoice To Cash" >
                                                GSB-Invoice To Cash
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="IT" >
                                                IT
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Legal" >
                                                Legal
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Market Access" >
                                                Market Access
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Prof. Ed." >
                                                Prof. Ed.
                                            </option>
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Marketing" >
                                                Marketing
                                            </option>
                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Sub Function</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="subfunctions"
                                            value={milestone.subfunctions}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>

                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Cross-Functional or Due Dillgence Related</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="crossfunctional"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={milestone.crossfunctional}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Cross-Functional
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="OTC">OTC</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="N/A">N/A</option>
                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Phase</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="phase"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={milestone.phase}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Phase
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Phase 1">Phase 1</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Phase 2">Phase 2</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Phase 3">Phase 3</option>
                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Description</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="description"
                                            value={milestone.description}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Start Date</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="date"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="startdate"
                                            value={milestone.startdate}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>

                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Finish Date</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="date"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="finishdate"
                                            value={milestone.finishdate}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Duration</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            readOnly
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="duration"
                                            value={milestone.duration}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Status</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder='Status'
                                            name="status"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={milestone.status}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Status
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="On-Track">On-Track</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Complete">Complete</option>

                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Complete</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="complete"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={milestone.complete}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="25%">25%</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="50%">50%</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="75%">75%</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="100%">100%</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Owner</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="owner"
                                            value={milestone.owner}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Dependencies</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="dependencies"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={milestone.dependencies}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Dependency
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="No">No</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Yes">Yes</option>

                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Dependency Function</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="dependenciesfunction"
                                            value={milestone.dependenciesfunction}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Dependency Locked?</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder=''
                                            name="dependencieslocked"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={milestone.dependencieslocked}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Dependencies Locked
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="No">No</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Yes">Yes</option>

                                        </select>
                                    </div>
                                </div>
                                <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px]'>

                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>InterLock Date</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="date"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="interlockduedate"
                                            value={milestone.interlockduedate}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Dependency Description</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="dependencydescritption"
                                            value={milestone.dependencydescritption}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Dependency Status</label>
                                        <select
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            placeholder='Status'
                                            name="dependencystatus"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                                            value={milestone.dependencystatus}
                                            onChange={handleInputChange}
                                        >
                                            <option className={`${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="" disabled>
                                                Select Status
                                            </option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="On-Track">On-Track</option>
                                            <option className={` ${theme.palette.mode === 'light' ? 'bg-black text-white' : 'bg-gray-500 text-black'}`} value="Complete">Complete</option>

                                        </select>
                                    </div>
                                    <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]">
                                        <label>Comments</label>
                                        <input
                                            placeholder=''
                                            style={{
                                                border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                            }}
                                            type="text"
                                            className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                            name="comments"
                                            value={milestone.comments}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-center">
                                <Button type="submit" onClick={handelAddMilestone} variant="contained" sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100], }} >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div >
            </div >
        </div >
    )
}

export default AddMilestone