import { Box } from "@mui/material";
import Header from "../../components/Header";
import FunctionChart from "../../components/FunctionChart";

const FunctionStatusChart = () => {
  return (
    <Box m="20px">
      <Header title="Function Status" subtitle="Status Chart" />
      <Box height="75vh">
        <FunctionChart />
      </Box>
    </Box>
  );
};

export default FunctionStatusChart;
