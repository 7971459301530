import React, { useEffect, useState } from 'react'
import { Box, Button, useTheme } from "@mui/material";
import { Amplify } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../components/Header';
import { tokens } from "../theme"
import Toaster from "../Toastify/Toaster"
import { toast } from 'react-toastify';
import AWS from 'aws-sdk';


AWS.config.update({
    region: 'eu-north-1',
    accessKeyId: 'AKIA6AWA27EBIZT3FY6W',
    secretAccessKey: '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',
});
const s3 = new AWS.S3();
const AccountModal = ({ EditUserModal, setEditUserModal, GetUserImage, CurrentUserImage }) => {
    const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [userImage, setuserImage] = useState("")
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [User, setUser] = useState("")
    const [Loading, setLoading] = useState(false)
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [ConfirmNewPassword, setConfirmNewPassword] = useState('')
    const fetchCurrentUser = async () => {
        setLoading(true)
        try {
            const userPoolId = 'eu-north-1_NLvW8NnoQ';
            const username = localStorage.getItem('username');
            const params = {
                UserPoolId: userPoolId,
                Username: username,
            };

            const response = await cognitoIdentityServiceProvider.adminGetUser(params).promise();
            // console.log(response)
            setUser(response);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching current user:', error);
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchCurrentUser()
    }, [])
    const handleChangePassword = async () => {
        try {
            const accessToken = localStorage.getItem("Token");
            const params = {
                AccessToken: accessToken,
                PreviousPassword: oldPassword,
                ProposedPassword: newPassword,
            };

            if (!oldPassword || !newPassword || !ConfirmNewPassword) {
                // Display a toast notification for empty fields
                toast.error('Please fill in all password fields', { theme: 'light' });
            } else if (newPassword !== ConfirmNewPassword) {
                // Display a toast notification for password mismatch
                toast.error('New password and confirm password do not match', { theme: 'light' });
            } else {
                // Proceed with changing the password
                let res = await cognitoIdentityServiceProvider.changePassword(params).promise();
                // console.log(res);
                // console.log('Password changed successfully');
                toast.success('Password Changed', { theme: 'light' });
            }
        } catch (error) {
            toast.error('Error changing password', { theme: 'light' });
            console.error(`Error changing password: ${error.message}`);
        }

    };
    const uid = function () {
        return Date.now() + Math.floor(Math.random());
    }
    const onChange = (e) => {
        const img = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const base64Image = event.target.result;
            const objectURL = URL.createObjectURL(img);
            const imgElement = document.createElement('img');
            imgElement.src = objectURL;
            setuserImage(base64Image);
        };
        reader.readAsDataURL(img);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Define S3 upload parameters
        const username = localStorage.getItem("username")
        const params = {
            Bucket: 'idealuserimage',
            Key: username,// Replace with your desired object key
            Body: userImage,
        };

        // Upload the image to S3
        s3.putObject(params, (err, data) => {
            if (err) {
                console.error('Error uploading image:', err);
            } else {
                console.log('Image uploaded successfully:', data);
                GetUserImage()
            }
        });
        setEditUserModal(!EditUserModal)
    };


    return (
        <div
            data-te-modal-init
            className={` fixed left-0 top-0 z-[1055] ${EditUserModal ? "block" : "hidden"} h-full w-full overflow-y-auto overflow-x-hidden outline-none`}
            id="exampleModalLg"
            tabIndex="-1"
            aria-labelledby="exampleModalLgLabel"
            aria-modal="true"
            role="dialog">
            <Toaster />
            <div
                className={`${theme.palette.mode === 'light' ? 'bg-[#eef0f2]' : 'bg-[#141b2d]'} 2xl:mt-[5px] 2xl:ml-[235px] 
                xl:mt-[5px] xl:ml-[150px] lg:mt-[5px] lg:ml-[100px] md:mt-[70px] md:ml-[70px] max-sm:mt-[50px] max-sm:ml-[50px] pointer-events-none  relative w-[80%] duration-300 ease-in-out`}
                style={{
                    boxShadow: `0px 0px 3px 0px  ${theme.palette.mode === "light" ? "black " : "white"}`,
                }}
                data-te-modal-dialog-ref>
                <div
                    className="pointer-events-auto relative flex w-full flex-col rounded-md border-none  bg-clip-padding text-current shadow-lg outline-none ">
                    <div
                        className="flex flex-shrink-0 items-center justify-between rounded-t-md  border-opacity-100 p-4 dark:border-opacity-50">
                        <Header title="Account Setting" sx={{ color: `${colors === 'light' ? 'black' : 'white'}` }} />
                        <Box sx={{
                            backgroundColor: '#2068bf',
                            color: '#e0e0e0',
                            display: 'flex',
                            width: '40px',
                            borderRadius: '50%',
                            padding: '10px 10px',
                            alignContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                            onClick={() => setEditUserModal(!EditUserModal)}
                        >
                            < CloseIcon sx={{ mr: "10px" }} />
                        </Box>
                    </div>
                    <div className="relative p-4">
                        <form onSubmit={handleSubmit}>
                            <div className='flex flex-col h-[75vh]  max-sm:h-[70vh] overflow-auto'>
                                <div className='flex flex-col items-center'>
                                    <div className='flex items-center justify-center'>
                                        <div className="mb-4 mr-2 w-[100%]" >
                                            <div>
                                                <img src={userImage ? userImage : CurrentUserImage} accept="image/*" alt='profile Image' width={"150"} height={"150"} className='bg-white  rounded-full cursor-pointer w-[150px] h-[150px]' />
                                                <input type="file" id='fileUpload' className='' style={{ display: "none" }} onChange={onChange} />
                                                <div className='relative bottom-11 left-[4rem] flex justify-center items-center bg-green-400 rounded-full h-14 w-14 cursor-pointer '>
                                                    <label htmlFor="fileUpload">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-camera w-[20px] h-[20px]  cursor-pointer" ><path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path><circle cx="12" cy="13" r="4"></circle></svg>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px] max-sm:w-[98%] w-[100%]'>
                                        <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                            <label>Name</label>
                                            <input
                                                style={{
                                                    border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                                }}
                                                placeholder='Enter Name Of User'
                                                type="text"
                                                readOnly
                                                className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}    h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                                name="name"
                                                value={User?.UserAttributes && User.UserAttributes.length > 3 ? User.UserAttributes[3].Value : ''}

                                            />
                                        </div>
                                        <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                            <label>Email</label>
                                            <input
                                                placeholder='Enter User Email'
                                                style={{
                                                    border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                                }}
                                                type="email"
                                                readOnly
                                                className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                                name="email"
                                                value={User?.UserAttributes && User.UserAttributes.length > 3 ? User.UserAttributes[4].Value : ''}
                                            />
                                        </div>
                                        <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                            <label>Profile/Function</label>
                                            <input
                                                style={{
                                                    border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                                }}
                                                placeholder='Username'
                                                type="email"
                                                readOnly
                                                className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}    h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                                name="username"
                                                value={User?.UserAttributes && User.UserAttributes.length > 3 ? User.UserAttributes[2].Value : ''}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex max-sm:flex max-sm:flex-col max-sm:mr-[5px] max-sm:w-[98%] w-[100%]'>
                                        <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                            <label>Current Password</label>
                                            <input
                                                placeholder='Current Password'
                                                style={{
                                                    border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                                }}
                                                type="password"
                                                className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                                name="cpassword"
                                                required
                                                value={oldPassword}
                                                onChange={(e) => setOldPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                            <label>New Password</label>
                                            <input
                                                placeholder='New Password'
                                                style={{
                                                    border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                                }}
                                                type="password"
                                                className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                                name="npassword"
                                                required
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-4 mr-2  w-[50%] max-sm:w-[100%]" >
                                            <label>Confirm New Password</label>
                                            <input
                                                placeholder='Confirm New Password'
                                                style={{
                                                    border: `1px solid ${theme.palette.mode === "light" ? "black" : "white"}`
                                                }}
                                                type="password"
                                                className={`block w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[55px] bg-transparent border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} placeholder:pl-2 p-2`}
                                                name="ncpassword"
                                                required
                                                value={ConfirmNewPassword}
                                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-center">
                                <Button type="submit" onClick={handleSubmit} variant="contained" sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100], }} >
                                    Add Photo
                                </Button>
                                <Button type="button" onClick={handleChangePassword} variant="contained" sx={{ marginLeft: "7px", backgroundColor: "red", color: "white" }} disabled={!oldPassword || !newPassword || !ConfirmNewPassword} >
                                    Change Password
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountModal