import { Link ,useLocation  } from 'react-router-dom';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Header from "../../../components/Header";
import AddchartIcon from '@mui/icons-material/Addchart';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAuth } from '../../../context/authContext';
import { useEffect } from 'react';
import Addproject from '../../../Modals/projectmodal/addproject';
import { useState } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ProjectInfo from '../../../Modals/ProjectInfo';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import RowingOutlined from '@mui/icons-material/RowingOutlined';
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import {
  DoneAllOutlined,
  AssignmentTurnedInOutlined,
  StreamOutlined,
  RuleOutlined,
  PrivacyTipOutlined,
  MultipleStopOutlined,
  BugReportOutlined,
  AssignmentOutlined,
  TourOutlined,
} from "@mui/icons-material";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 25,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const Completedprojects = ({ projectData, setprojectData, getprojectData, isLoading }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setcurrentproject } = useAuth()
  const [AddProjectToggle, setAddProjectToggle] = useState(false)
  const [OpenProjectInfo, setOpenProjectInfo] = useState(false)
  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path;
  };
  useEffect(() => {
    document.title = "iDeal - Completed Project";
  }, [])
  const completedData = Array.isArray(projectData)
    ? projectData.filter((completedProject) => completedProject.status === "Completed")
    : [];
  const handelGoBack = () => {
    window.history.back()
  }
  const handelProjectInfo = (data) => {
    setcurrentproject(data)
    setOpenProjectInfo(!OpenProjectInfo)
  }
  return (
    <>
      <Box className=" 2xl:m-[20px] xl:m-[20px] lg:m-[20px] md:m-[20px] max-sm:mx-[12px] max-sm:my-[30px]">
      <Box >
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
              <li className="inline-flex items-center">
                <Link
                  style={{fontWeight: '700',
                  fontFamily: 'sans-serif'}}
                  to="/"
                  className={`inline-flex items-center 2xl:text-lg xl:text-lg lg:text-sm md:text-sm sm:text-xs text-xs ${isActive('/') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600 `}
                >
                  <svg
                    className="w-3 h-3 mr-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M19.707 9.293l-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                  </svg>
                  All Projects
                </Link>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="w-3 h-3 text-gray-400 mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <AssignmentOutlined className={`mr-2 text-xs  ${isActive('/projects/active') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'}`} />
                  <Link
                    style={{fontWeight: '700',
                    fontFamily: 'sans-serif'}}
                    to="/projects/active"
                    className={`inline-flex items-center 2xl:text-lg xl:text-lg lg:text-sm md:text-sm sm:text-xs text-xs ${isActive('/projects/active') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600 `}
                  >
                    Active Projects
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="w-3 h-3 text-gray-400 mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <AssignmentTurnedInOutlined className={`mr-2 text-xs  ${isActive('/projects/completed') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'}`} />
                  <Link
                    style={{fontWeight: '700',
                    fontFamily: 'sans-serif'}}
                    to="/projects/completed"
                    className={`inline-flex items-center 2xl:text-lg xl:text-lg lg:text-sm md:text-sm sm:text-xs text-xs ${isActive('/projects/completed') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600 `}
                  >
                    Completed Projects
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </Box>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: "10px", marginTop: "20px" }} >
          <Header title="COMPLETED PROJECTS" />
          <Box display="flex" alignItems="center">
            <Box>
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  marginRight: "5px"
                }}
                onClick={() => setAddProjectToggle(!AddProjectToggle)}
              >
                <AddchartIcon sx={{ mr: "10px" }} />
                <span  className="max-md:text-[10px] text-[14px] 2xl:block xl:block lg:block md:block sm:hidden hidden"> Create Project</span>
              </Button>
            </Box>
            <Box>
              <button
                className="hover:bg-transparent"
                style={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  display:"flex",
                  alignItems:"center",
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "9px 20px",
                  zIndex: "-1",
                  borderRadius: "4px",
                  lineHeight: "1.75rem"
                }}
                onClick={handelGoBack}
              >
                <KeyboardBackspaceIcon sx={{ mr: "10px" }} />
                <span  className="2xl:block xl:block lg:block md:block sm:hidden hidden"> BACK </span>
              </button>
            </Box>
          </Box>
        </Box>

        {/* GRID & CHARTS */}
        {completedData?.length > 0 ? <Box
      className="grid grid-cols-12 grid-rows-auto-140 gap-[4rem] max-sm:flex max-sm:flex-col mt-[10px]"
        >
          {/* ROW 1 */}

          {completedData && completedData.map((data) => {
            return (
              <Box
                key={data.id}
                gridColumn="span 6"
                gridRow="span 2"
                height="260px"
                backgroundColor={colors.primary[400]}
                boxShadow={`0px 0px 3px 0px ${theme.palette.mode === "light" ? "black" : "white"} `}
              >
                <Box
                  mt="25px"
                  p="0 30px"
                  display="flex "
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color={colors.grey[100]}
                    >
                      ID: {data.projectname}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color={colors.grey[100]}
                    >
                      Overall Progress : {data.progress}
                    </Typography>
                  </Box>
                </Box>
                <Box p="15px">
                  <BorderLinearProgress variant="determinate" value={
                    data.progress === "On-Track" ? "20" :
                      data.progress === "Active" ? "30" :
                        data.progress === "InActive" ? "10" :
                          data.progress === "Completed" ? "100" :
                            "0"
                  } />
                </Box>
                <Box
                  mt="10px"
                  p="0 30px"
                  display="flex "
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography
                      variant="h6"
                      fontWeight="600"
                      color={colors.grey[100]}
                    >
                      Start Date:{data.startdate}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      fontWeight="600"
                      color={colors.grey[100]}
                    >
                      Finish Date: {data.finishdate}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  mt="20px"
                  display="flex "
                  justifyContent="center"
                  alignItems="center"
                  fontSize={"1rem"}
                >Project Status  <Box height={"25px"} color={"black"} marginLeft={"5px"} width={"100px"} textAlign={"center"} backgroundColor={
                  data.status === "Active" ? "yellow" :
                    data.status === "InActive" ? "red" :
                      data.status === "Completed" ? "green" :
                        "transparent"
                } border={"5px"}>{data.status}</Box> </Box>
                <Box
                  mt="25px"
                  p="0 30px"
                  display="flex "
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Box>
                      <Button sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "11px",
                        fontWeight: "normal",
                        padding: "5px 10px",
                      }}
                        onClick={() => handelProjectInfo(data)}
                      > Project Info </Button>
                    </Box>
                  </Box>
                  <Box>
                    <Link to="/projects/details">
                      <Button sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "11px",
                        fontWeight: "normal",
                        padding: "5px 10px",
                      }}
                        onClick={() => setcurrentproject(data)}
                      > Project Details</Button>
                    </Link>
                  </Box>
                </Box>
              </Box>
            )
          })
          }
        </Box>
          :
          (isLoading ? <div className="text-center m-12">
            <div role="status">
              <svg aria-hidden="true" className="inline w-12 h-12 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div> :
            <Box sx={{}}>
              <Box sx={{
                fontSize: "2rem",
                textAlign: 'center',
                marginTop: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <CancelIcon sx={{ mr: "10px", height: '2rem', width: '2rem' }} /> <span style={{}}> You Have Not Completed Any Projects.</span>
              </Box>
            </Box>
          )
        }

      </Box >
      {AddProjectToggle && <Addproject AddProjectToggle={AddProjectToggle} setAddProjectToggle={setAddProjectToggle} />}
      {OpenProjectInfo && <ProjectInfo OpenProjectInfo={OpenProjectInfo} setOpenProjectInfo={setOpenProjectInfo} />}
    </>
  );
};

export default Completedprojects;
