import React, { useEffect } from 'react'
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from '../../theme';
import { Link, useLocation } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Header from '../../components/Header';
import { MultipleStopOutlined } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import BusinessIcon from '@mui/icons-material/Business';

const Log = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path;
  };
  useEffect(() => {
    document.title = "iDeal - Dependencies Log"; 
  },[])
  return (
    <Box margin={"60px"}>
       <Box marginTop={"20px"}>
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <BusinessIcon className={`mr-2 text-xs  ${isActive('/dependencies') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'}`} />
                <Link
                  style={{
                    fontWeight: '700',
                    fontFamily: 'sans-serif'
                  }}
                  to="/dependencies"
                  className={`inline-flex items-center text-sm font-medium ${isActive('/dependencies') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600`}
                >
                  Dependencies
                </Link>
              </li>
              <li className="inline-flex items-center">
              <MultipleStopOutlined className={`mr-2 text-xs  ${isActive('/dependencies/tracker') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'}`} />
                <Link
                  style={{
                    fontWeight: '700',
                    fontFamily: 'sans-serif'
                  }}
                  to="/dependencies/tracker"
                  className={`inline-flex items-center text-sm font-medium ${isActive('/dependencies/tracker') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600`}
                >
                  Tracker
                </Link>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="w-3 h-3 text-gray-400 mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <VpnKeyIcon className={`mr-2 text-xs  ${isActive('/dependencies/log') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'}`} />
                  <Link
                    style={{
                      fontWeight: '700',
                      fontFamily: 'sans-serif'
                    }}
                    to="/dependencies/log"
                    className={`inline-flex items-center text-sm font-medium ${isActive('/dependencies/log') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600 `}
                  >
                    Log
                  </Link>
                </div>
              </li>
             
            </ol>
          </nav>
        </Box>
    <div className='flex justify-center items-center h-[50%] text-xl'>Log Page Is Under-Development</div>
    </Box>
  )
}

export default Log