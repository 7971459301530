import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const StatusBox = ({ title,  icon, statnumber }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    return (
       <Box width="100%" padding="20px 5px 20px 5px">
     <Box>
     <Box display="flex" alignItems="center" justifyContent="center">
     <Box mr={1}>
               
         {icon}
         </Box>
            
          <Typography
            variant="h2"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
            {statnumber}
          </Typography>
          
        </Box>
        <Box display="flex" justifyContent="center">
          <Box
            className="2xl:text-[16px] xl:text-[16px] lg:text-[14px] md:text-[10px] sm:text-[12px] text-[12px]"
            sx={{ color: colors.grey[100] }}
          >
            {title}
          </Box>
          </Box>
        </Box>
        </Box>
        
    )
};

export default StatusBox;

