import React, { useEffect, useState } from 'react'
import { Button, useTheme } from "@mui/material";
import { Link } from 'react-router-dom';

const Notification = ({ opennotification, setopennotification, notification }) => {
    const theme = useTheme();
    function formatTimeDifference(createdAtDate) {
        const now = new Date();
        const timeDifferenceInSeconds = Math.floor((now - createdAtDate) / 1000);

        if (timeDifferenceInSeconds < 60) {
            return "few seconds ago";
        } else if (timeDifferenceInSeconds < 3600) {
            const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
            return minutesAgo === 1 ? "a minute ago" : `${minutesAgo} minutes ago`;
        } else if (timeDifferenceInSeconds < 86400) {
            const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
            return hoursAgo === 1 ? "an hour ago" : `${hoursAgo} hours ago`;
        } else {
            const daysAgo = Math.floor(timeDifferenceInSeconds / 86400);
            return daysAgo === 1 ? "a day ago" : `${daysAgo} days ago`;
        }
    }
    const handelRead = () => {

    }
    return (
        <div id="dropdownNotification"
            className={`z-20 ${opennotification ? "block" : "hidden"} absolute max-md:right-[45px] right-[110px] top-[60px] max-md:w-[75%]  w-full max-w-[20rem] bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-800 dark:divide-gray-700`}
            aria-labelledby="dropdownNotificationButton">
            <div
                className="block px-4 py-2 font-medium text-center text-gray-700 rounded-t-lg bg-gray-50 dark:bg-gray-800 dark:text-white">
                Notifications
            </div>
            <div className="divide-y divide-gray-100 dark:divide-gray-700">
                <div className="overflow-y-auto h-[250px]">
                    {notification.length > 0 ? notification.map((notify) => {
                        const createdAtDate = new Date(notify.createAt);
                        const timeDifferenceString = formatTimeDifference(createdAtDate);

                        return (
                            <Link to="/" key={notify.id} className="flex px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-700">
                                <div className="w-full pl-3">
                                    <div className="text-gray-500 text-sm  dark:text-gray-400">New Task Added <span
                                        className="flex flex-col font-semibold text-gray-900 dark:text-white">Title: {notify.title}</span>
                                       <span className="flex flex-col overflow-hidden whitespace-nowrap  text-gray-900 dark:text-white"> Description : {notify.description} </span></div>
                                    <div className="text-xs text-blue-600 dark:text-blue-500">{timeDifferenceString}</div>
                                </div>
                            </Link>
                        );
                    }) : "Notification not available"}

                </div>
                <Button
                    onClick={handelRead}
                    className="block py-2 text-sm font-medium text-center w-full text-black rounded-b-lg bg-gray-50 hover:bg-[#ffffff14] dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white">
                    <div className="items-center text-white">
                        Read all
                    </div>
                </Button>
            </div>
        </div>
    )
}

export default Notification