import { Box, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeIcon from '@mui/icons-material/Home';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import BusinessIcon from '@mui/icons-material/Business';

const Sidebar2 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path;
    };
    return (
        <Box style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            height: '80vh',
        }}>
            <Box>
                <Link
                    to="/"
                    className="2xl:text-[40px] xl:text-[40px]  ml-2 lg:text-[32px]"
                >
                    <span className="2xl:text-[40px]  xl:text-[40px] lg:text-[32px] text-blue-700">i</span>
                    Deal
                </Link>
            </Box>
            <Box marginTop={"25px"}>
                <Box
                    className="p-[10px] 2xl:text-[15px]  xl:text-[14px]  lg:text-[12px]"
                >
                    <Link
                        title="PROJECTS"
                        to="/"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        className={` ${isActive('/') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-700`}
                    >
                        <HomeIcon />
                        <span className="p-[10px]">
                            PROJECTS
                        </span>
                    </Link>
                </Box>
                <Box
                    className="p-[10px] 2xl:text-[15px]  xl:text-[14px]  lg:text-[12px]"
                >
                    <Link
                        title={"WORKPLAN"}
                        to="/workplan"
                        color={colors.grey[300]}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        className={` ${isActive('/workplan') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-700`}
                    >
                        <WorkOutlineIcon />
                        <span className="p-[10px]">
                            WORKPLAN
                        </span>
                    </Link>
                </Box>
                <Box
                    className="p-[10px] 2xl:text-[15px]  xl:text-[14px]  lg:text-[12px]"
                >
                    <Link
                        title={"DEPENDENCIES"}
                        to="/dependencies/tracker"
                        color={colors.grey[300]}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        className={`${isActive('/dependencies/tracker') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-700`}
                    >
                        <BusinessIcon />
                        <span className="p-[10px]">
                            DEPENDENCIES
                        </span>
                    </Link>
                </Box>
                <Box
                    className="p-[10px] 2xl:text-[15px]  xl:text-[14px]  lg:text-[12px]"
                >
                    <Link
                        to="/risk"
                        variant="h6"
                        color={colors.grey[300]}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        className={` ${isActive('/risk') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-700`}
                    >
                        <ReportProblemIcon />
                        <span className="p-[9px]">
                            RAID LOG
                        </span>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default Sidebar2;
