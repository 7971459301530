import React, { useState, useEffect } from 'react'
import { Box, Button, useTheme } from "@mui/material";
import { Amplify } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import Header from '../../components/Header';
import { tokens } from "../../theme"
import Toaster from "../../Toastify/Toaster"
import { toast } from 'react-toastify';
import AWS from 'aws-sdk';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";


const AllUsers = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [users, setUsers] = useState([]);
    const [isloading, setisLoading] = useState(true);
    AWS.config.update({
        region: 'eu-north-1',
        accessKeyId: 'AKIA6AWA27EBIZT3FY6W',
        secretAccessKey: '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',
    });
    Amplify.configure({
        Auth: {
            region: 'eu-north-1',
            userPoolId: 'eu-north-1_NLvW8NnoQ',
            userPoolWebClientId: '3559i49ok8ro4iqei4im5s5v8u',
        },
    });


    const fetchCognitoUsers = async () => {
        try {
            setisLoading(true)
            const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

            const userPoolId = 'eu-north-1_NLvW8NnoQ'; // Replace with your User Pool ID

            const params = {
                UserPoolId: userPoolId,
            };

            const response = await cognitoIdentityServiceProvider.listUsers(params).promise();

            // Set the list of users in your state
            setUsers(response.Users);
            setisLoading(false);
        } catch (error) {
            console.error('Error fetching Cognito users:', error);
            setisLoading(false);
        }
    }

    useEffect(() => {
        fetchCognitoUsers();
    }, []);
    //console.log(users)

    const columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 0.5
        },
        {
            field: "Username",
            headerName: "Username",
            flex: 0.5
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email",
        },
        {
            field: "profile",
            headerName: "Profile",
            flex: 1,
        },
        {
            field: "UserStatus",
            headerName: "User Status",
            flex: 1,
        },
        {
            field: 'confirmuser',
            headerName: 'Confirm User',
            flex: 1,
            renderCell: (params) => {
                if (params.row.UserStatus === "CONFIRMED") {
                    return (
                        null
                    )
                }
                else {
                    return (
                        <Button
                            sx={{
                                fontSize: "7px",
                                fontWeight: "bold",
                                padding: "5px 5px",
                                backgroundColor: "green"
                            }}
                            onClick={() => confirmUserStatus(params.row.Username)}
                        >
                            < CheckIcon sx={{ mr: "10px", color: `${theme.palette.mode === "light" ? "black" : "white"}` }} />
                        </Button>
                    );
                }

            },
        },
        {
            field: 'deleteuser',
            headerName: 'Delete User',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Button
                        sx={{
                            fontSize: "7px",
                            fontWeight: "bold",
                            padding: "5px 5px",
                        }}
                        onClick={() => handleDeleteUser(params.row.Username)}
                    >
                        < DeleteForeverIcon sx={{ mr: "10px", color: `${theme.palette.mode === "light" ? "black" : "white"}` }} />
                    </Button>
                );
            },
        },
    ];

    const handleDeleteUser = async (username) => {
        //console.log(username)
        const cognito = new AWS.CognitoIdentityServiceProvider();
        const params = {
            UserPoolId: 'eu-north-1_NLvW8NnoQ', // Replace with your User Pool ID
            Username: username, // Replace with the username you want to delete
        };

        cognito.adminDeleteUser(params, (err, data) => {
            if (err) {
                console.error('Error deleting user:', err);
            } else {
                //console.log('User deleted successfully:', data);
                fetchCognitoUsers();
            }
        });
    }
    const confirmUserStatus = async (username) => {
        //console.log('Confirmed:', username);
        const requestBody = {
            UserPoolId: "eu-north-1_NLvW8NnoQ",
            Username: username,
        };
        const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/confirmuser';
        try {
            const response = await fetch(`${lambdaEndpoint}`, {
                method: 'POST',
                headers: {
                    'x-api-key': 'WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty',
                },
                body: JSON.stringify(requestBody), // Convert the data to JSON format
            });

            const data = await response.json();
            fetchCognitoUsers()
            //console.log('Response:', response);
            //console.log('Response Data:', data);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    return (
        <div style={{ margin: "10px 10px" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="All Users" subtitle="List of User" />
            </Box>
            {users?.length > 0 ? <Box>
                <Box
                    m="10px 0 0 0"
                    height="75vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-main": {
                            border: ` 1px solid ${theme.palette.mode === "light" ? "black" : "white"}`,
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .name-column--cell": {
                            color: `${theme.palette.mode === "light" ? "black" : "white"}`,
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colors.blueAccent[700],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: "",
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`,
                        },
                        '& .completed': {
                            backgroundColor: '#29e50d',
                            color: 'black',
                        },
                        '& .inactive': {
                            backgroundColor: 'red',
                            color: 'black',
                        },
                        '& .active': {
                            backgroundColor: 'yellow',
                            color: 'black',
                        },
                    }}
                >
                    <DataGrid
                        getRowId={(row) => row.id}
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        count={users.length}
                        rows={users.map((row, index) => ({
                            id: index,
                            Username: row.Username,
                            name: row.Attributes[3]?.Value,
                            email: row.Attributes[4]?.Value,
                            profile: row.Attributes[2]?.Value,
                            UserStatus: row.UserStatus
                        }))}
                        columns={columns}
                        components={{ Toolbar: GridToolbar }}

                    />

                </Box>
            </Box>
                :
                (isloading ? <div className="text-center m-12">
                    <div role="status">
                        <svg aria-hidden="true" className="inline w-12 h-12 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div> :
                    <Box sx={{}}>
                        <Box sx={{
                            fontSize: "2rem",
                            textAlign: 'center',
                            marginTop: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <CancelIcon sx={{ mr: "10px", height: '2rem', width: '2rem' }} /> <span style={{}}> No Users Found</span>
                        </Box>
                    </Box>
                )
            }
        </div>
    )
}

export default AllUsers