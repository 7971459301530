import { Box, Button } from '@mui/material';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import Header from '../../components/Header';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddWorkplan from "../../Modals/workplanmodal/addworkplan";
import EditWorkplan from "../../Modals/workplanmodal/editworkplan";
import CancelIcon from '@mui/icons-material/Cancel';
import { DoneAllOutlined } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditTask from '../../Modals/taskmodal/edittask';
import EditMilestone from '../../Modals/milestonemodal/editmilestone';
import AddMilestone from '../../Modals/milestonemodal/addmilestone';
import AddTask from '../../Modals/taskmodal/addtask';
import { TourOutlined } from "@mui/icons-material";
import { AssignmentOutlined } from "@mui/icons-material";
import { useAuth } from '../../context/authContext';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import RowingOutlined from '@mui/icons-material/RowingOutlined';
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import {
  AssignmentTurnedInOutlined,
  StreamOutlined,
  RuleOutlined,
  PrivacyTipOutlined,
  MultipleStopOutlined,
  BugReportOutlined,
} from "@mui/icons-material";
const TreeDataSimple = ({ projectData }, Getnofication) => {
  const theme = useTheme();
  const { currentproject } = useAuth();
  const colors = tokens(theme.palette.mode);
  const [AddModalToggle, setAddModalToggle] = useState(false);
  const [AddMilestoneModalToggle, setAddMilestoneModalToggle] = useState(false);
  const [EditModalToggle, setEditModalToggle] = useState(false);
  const userid = localStorage.getItem("user")
  const [isLoading, setisLoading] = useState(false)
  const [TaskData, setTaskData] = useState("")
  const [milestoneData, setmilestoneData] = useState("")
  const initialState = currentproject?.projectname ? currentproject?.projectname : ""
  const [selectedProject, setselectedProject] = useState(initialState)
  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path;
  };
  useEffect(() => {
    document.title = "iDeal - Workplan";
  }, [])

  const columns = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   width: 0.5
    // },
    // {
    //   field: "projectname",
    //   headerName: "Project Name",
    //   width: 200,
    // },

    {
      field: "title",
      headerName: "Title",
      width: 200,
    },
    {
      field: "functions",
      headerName: "Function",
      width: 200,
    },
    {
      field: "subfunctions",
      headerName: "Sub-Function",
      width: 200,
    },
    {
      field: "crossfunctional",
      headerName: "Cross-Functional or Due Dillgence Related",
      width: 200,
    },
    {
      field: "phase",
      headerName: "Phase",
      width: 200,
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
    },
    {
      field: "startdate",
      headerName: "Start Date",
      width: 150,
    },
    {
      field: "finishdate",
      headerName: "Finish Date",
      width: 150,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "complete",
      headerName: "Complete",
      width: 150,
    },
    {
      field: "owner",
      headerName: "Owner",
      width: 150,
    },
    {
      field: "dependencies",
      headerName: "Dependencies",
      width: 200,
    },
    {
      field: "dependenciesfunction",
      headerName: "Dependencies Function",
      width: 200,
    },
    {
      field: "dependencieslocked",
      headerName: "Dependencies Locked?",
      width: 200,
    },
    {
      field: "interlockduedate",
      headerName: "InterLock Due Date",
      width: 200,
    },
    {
      field: "dependencydescritption",
      headerName: "Dependency Description",
      width: 400,
    },
    {
      field: "dependencystatus",
      headerName: "Dependency Status",
      width: 200,
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 400,
    },
    {
      field: 'edittask',
      headerName: 'Edit Task',
      flex: 1,
      renderCell: (params) => {
        if (params.row.parentid === null) {
          // If it's a parent row, return null (hide the edit icon)
          return null;
        }
        return (
          <Button
            sx={{
              fontSize: "7px",
              fontWeight: "bold",
              padding: "5px 5px",
            }}
            onClick={() => handleEdit(params.row)}

          >
            < EditNoteIcon sx={{ mr: "10px", color: `${theme.palette.mode === "light" ? "black" : "white"}` }} />
          </Button>
        );
      },
    },
    {
      field: 'deletetask',
      headerName: 'Delete Task',
      flex: 1,
      renderCell: (params) => {
        if (params.row.parentid === null) {
          // If it's a parent row, return null (hide the edit icon)
          return null;
        }
        return (
          <Button
            sx={{
              fontSize: "7px",
              fontWeight: "bold",
              padding: "5px 5px",
            }}
            onClick={() => handleDelete(params.row.id)}
          >
            < DeleteForeverIcon sx={{ mr: "10px", color: `${theme.palette.mode === "light" ? "black" : "white"}` }} />
          </Button>
        );
      },
    },
  ];
  const uid = function () {
    return Date.now() + Math.floor(Math.random());
  }
  const getmilestoneData = async (userid) => {
    setisLoading(true)
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/milestone';
    try {
      const response = await fetch(`${lambdaEndpoint}?userid=${userid}`, {
        method: 'GET',
        headers: {
          "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
        },
      });

      const data = await response.json();
      //console.log('Response:', response);
      //console.log('Response Data:', data);
      setmilestoneData(data.Item)
    } catch (error) {
      console.error('Error:', error);
    }
    setisLoading(false)
  }
  const gettaskData = async (userid) => {
    setisLoading(true)
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/task';
    try {
      const response = await fetch(`${lambdaEndpoint}?userid=${userid}`, {
        method: 'GET',
        headers: {
          "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
        },
      });

      const data = await response.json();
      //console.log('Response:', response);
      //console.log('Response Data:', data);
      setTaskData(data.Item)
    } catch (error) {
      console.error('Error:', error);
    }
    setisLoading(false)
  }

  const handleDelete = async (rowId) => {
    //console.log(`Delete Task clicked for row with ID: ${rowId}`);
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/task';
    const body = JSON.stringify({ "id": rowId });
    try {
      const response = await fetch(`${lambdaEndpoint}`, {
        method: 'DELETE',
        headers: {
          "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
        },
        body: body
      });
      //console.log('Delete operation successful', response);
      const userid = localStorage.getItem("user");
      gettaskData(userid);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const [EditId, setEditId] = useState("")
  const handleEdit = (rowId) => {
    //console.log(`Edit Task clicked for row with ID: ${rowId.id}`);
    setEditId(rowId)
    //console.log(rowId)
    setEditModalToggle(!EditModalToggle)
  }

  const combinedData = [...milestoneData, ...TaskData];
  //console.log("Combined Data:", combinedData);
  const [notification, setnotification] = useState({
    userid: userid,
    id: uid(),
    title: "",
    description: "",
    createAt: new Date(),
    isRead: false,
  })
  const AddNotification = async () => {
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/notification';

    try {
      const response = await fetch(`${lambdaEndpoint}`, {
        method: 'POST',
        headers: {
          "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
        },
        body: JSON.stringify(notification)
      });

      const data = await response.json();
      //console.log('Response:', response);
      //console.log('Response Data:', data);
    } catch (error) {
      console.error('Error:', error);
    }
  }
  const [task, settask] = useState({
    userid: userid,
    id: uid(),
    parentid: "",
    projectname: "",
    title: "",
    functions: "",
    subfunctions: "",
    crossfunctional: "",
    phase: "",
    description: "",
    startdate: "",
    finishdate: "",
    duration: "",
    status: "",
    complete: "",
    owner: "",
    dependencies: "",
    dependenciesfunction: "",
    dependencieslocked: "",
    interlockduedate: "",
    dependencydescritption: "",
    dependencystatus: "",
    comments: "",
  })
  const [milestone, setmilestone] = useState({
    userid: userid,
    id: uid(),
    parentid: null,
    projectname: "",
    title: "",
    functions: "",
    subfunctions: "",
    crossfunctional: "",
    phase: "",
    description: "",
    startdate: "",
    finishdate: "",
    duration: "",
    status: "",
    complete: "",
    owner: "",
    dependencies: "",
    dependenciesfunction: "",
    dependencieslocked: "",
    interlockduedate: "",
    dependencydescritption: "",
    dependencystatus: "",
    comments: "",
  })
  useEffect(() => {
    const userid = localStorage.getItem("user");
    getmilestoneData(userid);
    gettaskData(userid);
  }, [milestone, setmilestone, task, settask]);



  const projectMilestoneTask = Array?.isArray(combinedData) ? combinedData?.filter((milesTask) => milesTask.projectname === selectedProject) : []


  return (
    <>
      <div style={{ width: '95%', margin:"10px 10px" }} >
        <Box >
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
              <li className="inline-flex items-center">
                <Link
                  style={{
                    fontWeight: '700',
                    fontFamily: 'sans-serif'
                  }}
                  to="/workplan"
                  className={`inline-flex items-center text-sm font-medium ${isActive('/workplan') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600`}
                >
                  <svg
                    className="w-3 h-3 mr-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M19.707 9.293l-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                  </svg>
                  Workplan
                </Link>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="w-3 h-3 text-gray-400 mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <DoneAllOutlined className={`mr-2 text-xs  ${isActive('/task') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'}`} />
                  <Link
                    style={{
                      fontWeight: '700',
                      fontFamily: 'sans-serif'
                    }}
                    to="/task"
                    className={`inline-flex items-center text-sm font-medium ${isActive('/task') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600 `}
                  >
                    Tasks
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="w-3 h-3 text-gray-400 mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <TourOutlined className={`mr-2 text-xs  ${isActive('/milestone') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'}`} />
                  <Link
                    style={{
                      fontWeight: '700',
                      fontFamily: 'sans-serif'
                    }}
                    to="/milestone"
                    className={`inline-flex items-center text-sm font-medium ${isActive('/milestone') ? (theme.palette.mode === "light" ? "text-black" : "text-white") : 'text-gray-700'} hover:text-blue-600 `}
                  >
                    Milestone
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="20px">
          <Header title="Workplan" subtitle="List of Tasks" />
          <Box display="flex" alignItems="center ">
            <Box sx={{ marginRight: "5px" }}>
              <div className="mb-4 mr-2 w-[100%] cursor-pointer" >
                <label style={{
                  fontFamily: 'sans-serif',
                  fontWeight: '700'
                }}>Select Project</label>
                <select
                  style={{
                    backgroundColor: theme.palette.mode === 'light' ? '#0393fb' : '#2068bf',
                    borderRadius: '4px',
                    fontFamily: "sans-serif",
                    fontWeight: "700"
                  }}
                  placeholder=''
                  name="project"
                  className={`block cursor-pointer w-[100%] ${theme.palette.mode === 'light' ? 'text-black' : 'text-white'}   h-[45px] bg-transparent  border-none ${theme.palette.mode === "light" ? "placeholder:text-black" : "placeholder:text-white"} ${theme.palette.mode === 'light' ? ' text-black' : 'text-white'} placeholder:pl-2 p-2`}
                  value={selectedProject}
                  onChange={(event) => {
                    const selectedProject = event.target.value;
                    setselectedProject(selectedProject);
                  }}
                >
                  <option
                    className={`cursor-pointer ${theme.palette.mode === 'light'
                      ? 'bg-black text-white'
                      : 'bg-gray-500 text-black'
                      }`}
                    value=""
                    disabled
                  >
                    <AssignmentOutlined sx={{ mr: "2px", color: `${theme.palette.mode === "light" ? "black" : "black"}` }} />
                    Select Project
                  </option>
                  {projectData?.length > 0 ? (
                    projectData.map((project, index) => {
                      return (

                        <option
                          key={index}
                          className={`cursor-pointer ${theme.palette.mode === 'light'
                            ? 'bg-black text-white'
                            : 'bg-gray-500 text-black'
                            }`}
                          value={project.projectname}
                        >
                          {project.projectname}
                        </option>
                      )

                    })
                  ) : (
                    <option value="" disabled>
                      No Projects are available
                    </option>
                  )}
                </select>
              </div>
            </Box>
            <Box>
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  marginRight: "10px"
                }}
                onClick={() => setAddMilestoneModalToggle(!AddMilestoneModalToggle)}
              >
                < TourOutlined sx={{ mr: "10px" }} />
                <span  className="max-md:text-[10px] text-[14px] 2xl:block xl:block lg:block md:block sm:hidden hidden"> Add Milestone</span>
              </Button>
            </Box>
            <Box>
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                onClick={() => setAddModalToggle(!AddModalToggle)}
              >
                < DoneAllOutlined sx={{ mr: "10px" }} />
                <span  className="max-md:text-[10px] text-[14px] 2xl:block xl:block lg:block md:block sm:hidden hidden"> Add Task</span>
              </Button>
            </Box>
          </Box>
        </Box>
        {projectMilestoneTask?.length > 0 ? <Box>
          <Box
            m="10px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-main": {
                border: ` 1px solid ${theme.palette.mode === "light" ? "black" : "white"}`,
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: `${theme.palette.mode === "light" ? "black" : "white"}`,
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
              '& .Confirmed': {
                backgroundColor: '#041541',
                color: `${theme.palette.mode === "light" ? "white" : "white"}`,
              },
              '& .Open': {
                backgroundColor: '#26aad5',
                color: `${theme.palette.mode === "light" ? "black" : "white"}`,
              },
              '& .High': {
                backgroundColor: 'red',
                color: `${theme.palette.mode === "light" ? "black" : "black"}`,
              },
              '& .Medium': {
                backgroundColor: 'orange',
                color: `${theme.palette.mode === "light" ? "black" : "black"}`,
              },
              '& .Low': {
                backgroundColor: 'yellow',
                color: `${theme.palette.mode === "light" ? "black" : "black"}`,
              },
              '& .Bold': {
                fontWeight: '700',
                fontFamily: "sans-serif"
              },
              '& .groupingField': {
                width: '10px',
              },
            }}
          >
            <DataGridPro
              treeData
              rows={projectMilestoneTask}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              getTreeDataPath={(row) => {
                const path = [];
                if (row.parentid === null) {
                  path.push(row.id);
                } else {
                  const parentMilestoneId = row.parentid;
                  path.push(parentMilestoneId);
                  path.push(row.title);
                }
                return path;
              }}
              getRowClassName={(params, row) => {
                if (params.row.parentid === null) {
                  return "Bold"
                }
                else if (params.field === "Group") {
                  return "Group"
                }
              }}
              getRowId={(row) => row.id}
              getCellClassName={(params) => {
                if (params.value === 'Confirmed') {
                  return 'Confirmed';
                }
                else if (params.value === 'Open') {
                  return 'Open';
                }
                else if (params.value === 'High') {
                  return 'High';
                }
                else if (params.value === 'Medium') {
                  return 'Medium';
                }
                else if (params.value === 'Low') {
                  return 'Low';
                }
              }}
            />

          </Box>
        </Box>
          :
          (isLoading ? <div className="text-center m-12">
            <div role="status">
              <svg aria-hidden="true" className="inline w-12 h-12 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div> :
            <Box sx={{}}>
              <Box sx={{
                fontSize: "2rem",
                textAlign: 'center',
                marginTop: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <CancelIcon sx={{ mr: "10px", height: '2rem', width: '2rem' }} /> <span style={{}}> Data Are Not Available For Workplan.</span>
              </Box>
            </Box>
          )
        }

      </div>
      {EditModalToggle && <EditTask projectData={projectData} EditId={EditId} setEditId={setEditId} milestoneData={milestoneData} gettaskData={gettaskData} EditModalToggle={EditModalToggle} setEditModalToggle={setEditModalToggle} />}
      {AddMilestoneModalToggle && <AddMilestone projectData={projectData} AddMilestoneModalToggle={AddMilestoneModalToggle} setAddMilestoneModalToggle={setAddMilestoneModalToggle} getmilestoneData={getmilestoneData} milestone={milestone} setmilestone={setmilestone} milestoneData={milestoneData} />}
      {AddModalToggle && <AddTask projectData={projectData} AddNotification={AddNotification} Getnofication={Getnofication} setnotification={setnotification} task={task} settask={settask} gettaskData={gettaskData} milestoneData={milestoneData} setAddModalToggle={setAddModalToggle} AddModalToggle={AddModalToggle} />}

    </>
  )
}

export default TreeDataSimple
