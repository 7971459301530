import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Login from "./scenes/login";
import Dashboard from "./scenes/dashboard";
import ActiveProjects from "./scenes/projects/activeprojects";
import CompletedProjects from "./scenes/projects/completedprojects";
import ProjectDetails from "./scenes/projects/details";
import ProjectInfo from "./scenes/projects/info";
import Invoices from "./scenes/invoices";
import Risk from "./scenes/risk";
import Actions from "./scenes/actions";
import Tasks from "./scenes/tasks";
import Milestones from "./scenes/milestones";
import Workplan from "./scenes/workplan";
import Issues from "./scenes/issues";
import Decision from "./scenes/decision";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import FunctionStatusChart from "./scenes/functionstatus";
import Pie from "./scenes/pie";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import { useNavigate } from "react-router-dom";
import AddProjectPage from "./scenes/projects/activeprojects/addproject";
import Tracker from "./scenes/dependencies/tracker";
import Log from "./scenes/dependencies/log";
import AllUsers from "./scenes/users/AllUsers";
import { useAuth } from "./context/authContext";
import Navbar from "./scenes/global/Navbar";
import Sidebar2 from "./scenes/global/SideBar2";

function App() {
  const [theme, colorMode] = useMode();
  const navigate = useNavigate()
  const { currentUser, setcurrentUser } = useAuth()
  const [isSidebar, setIsSidebar] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setisLoading] = useState(false)
  const [projectData, setprojectData] = useState("")

  let tkn = localStorage.getItem("Token")
  useEffect(() => {
    const userId = localStorage.getItem("user")
    getprojectData(userId)
  }, [tkn])
  useEffect(() => {
    if (currentUser !== null) {
      const intervalId = setInterval(() => {
        const userid = localStorage.getItem("user");
        if (userid !== currentUser) {
          localStorage.clear();
          setcurrentUser(null);
          navigate("/login");
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [currentUser]);

  const getprojectData = async (userid) => {
    setisLoading(true)
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/project';
    try {
      const response = await fetch(`${lambdaEndpoint}?userid=${userid}`, {
        method: 'GET',
        headers: {
          "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
        },
      });

      const data = await response.json();
      //console.log('Response:', response);
      //console.log('Response Data:', data);
      setprojectData(data.Item)
    } catch (error) {
      console.error('Error:', error);
    }
    setisLoading(false)
  }
  const uid = function () {
    return Date.now() + Math.floor(Math.random());
  }
  const [notification, setnotification] = useState("")
  const Getnofication = async () => {
    const userid = localStorage.getItem("user")
    setisLoading(true)
    const lambdaEndpoint = 'https://td8erpi96i.execute-api.eu-north-1.amazonaws.com/prod/notification';
    try {
      const response = await fetch(`${lambdaEndpoint}?userid=${userid}`, {
        method: 'GET',
        headers: {
          "x-api-key": "WoH2wnhbLk3PMChPgxxZIawGrYgTe9MX9Lt8Yhty"
        },
      });

      const data = await response.json();
      //console.log('Response:', response);
      //console.log('Response Data:', data);
      setnotification(data.Item)
    } catch (error) {
      console.error('Error:', error);
    }
    setisLoading(false)
  }


  return (

    <div className="app">
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/* {tkn && <div className="2xl:hidden xl:hidden lg:hidden md:block sm:block block">
           <Sidebar isSidebar={isSidebar} />
          </div>} */}
          {/* {tkn && <Sidebar isSidebar={isSidebar} />} */}
          {tkn && <div className="w-[8%] overflow-hidden 2xl:block xl:block lg:block md:hidden sm:hidden hidden">
           <Sidebar2 isSidebar={isSidebar} />
          </div>}
          <main className="content" style={{ height: "100vh", overflowY: "auto" }} >
          {tkn && <Topbar setIsSidebar={setIsSidebar} setLoggedIn={setLoggedIn} loggedIn={loggedIn} Getnofication={Getnofication} notification={notification} setnotification={setnotification} />}
            {/* {tkn && <Navbar setIsSidebar={setIsSidebar} setLoggedIn={setLoggedIn} loggedIn={loggedIn} Getnofication={Getnofication} notification={notification} setnotification={setnotification} />} */}
            <Routes>
              <Route path="/login" element={tkn ? <Navigate to="/" /> : <Login setLoggedIn={setLoggedIn} loggedIn={loggedIn} />} />
            </Routes>
            {!tkn ? <Navigate to="/login" /> :
              <Routes>
                <Route path="/" element={<Dashboard projectData={projectData} setprojectData={setprojectData} getprojectData={getprojectData} isLoading={isLoading} />} />
                <Route path="/projects/active" element={<ActiveProjects projectData={projectData} setprojectData={setprojectData} getprojectData={getprojectData} isLoading={isLoading} />} />
                <Route path="/createproject" element={<AddProjectPage projectData={projectData} setprojectData={setprojectData} />} />
                <Route path="/projects/completed" element={<CompletedProjects projectData={projectData} setprojectData={setprojectData} getprojectData={getprojectData} isLoading={isLoading} />} />
                <Route path="/projects/details" element={<ProjectDetails projectData={projectData} setprojectData={setprojectData} />} />
                {/* <Route path="/projects/info" element={<ProjectInfo  projectData={projectData} setprojectData={setprojectData}/>} /> */}
                <Route path="/dependencies/tracker" element={<Tracker />} />
                <Route path="/users" element={<AllUsers />} />
                <Route path="/dependencies/log" element={<Log />} />
                <Route path="/risk" element={<Risk projectData={projectData} />} />
                <Route path="/actions" element={<Actions projectData={projectData} />} />
                <Route path="/task" element={<Tasks projectData={projectData} Getnofication={Getnofication} />} />
                <Route path="/milestone" element={<Milestones projectData={projectData} />} />
                <Route path="/workplan" element={<Workplan projectData={projectData} />} />
                <Route path="/issues" element={<Issues projectData={projectData} />} />
                <Route path="/decision" element={<Decision projectData={projectData} />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/form" element={<Form />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/line" element={<Line />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/functionstatus" element={<FunctionStatusChart />} />
              </Routes>
            }
          </main>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </div>
  );
}

export default App;
